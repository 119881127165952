import React from "react";
import styled from "@emotion/styled";

const Content = styled.div`
  overflow-y: auto;
  height: 100%;
  margin-right: 25px;
`;

export default function Impressum() {
  return (
    <Content>
      <div className="col-12">
        <h3>Datenschutz-Hinweise</h3>
        <br />
        <p class="p1">1. Zwecke und Rechtsgrundlagen der Datenverarbeitung</p>
        <p class="p1">1.1 Informatorische Nutzung der Website</p>
        <p class="p1">
          Sie k&ouml;nnen unsere Website besuchen, ohne Angaben zu Ihrer Person
          zu machen. Wenn Sie unsere Website lediglich informatorisch nutzen
          (also sich nicht anmelden, registrieren oder einen Vertrag
          abschlie&szlig;en) oder uns sonst keine Informationen zu Ihrer Person
          &uuml;bermitteln, verarbeiten wir keine personenbezogenen Daten, mit
          Ausnahme der Daten, die Ihr Browser &uuml;bermittelt, um Ihnen den
          Besuch der Webseite zu erm&ouml;glichen sowie Informationen, die uns
          im Rahmen von eingesetzten Cookies zur statistischen Analyse der
          Nutzung unser Website &uuml;bermittelt werden.
        </p>
        <p class="p1">
          1.1.1 Technische Bereitstellung der Website und Einsatz von Cookies
        </p>
        <p class="p1">
          Zum Zweck der technischen Bereitstellung der Website ist es
          erforderlich, dass wir bestimmte, automatisch &uuml;bermittelte
          Informationen von Ihnen verarbeiten, damit Ihr Browser unsere Website
          anzeigen kann und Sie die Website nutzen k&ouml;nnen. Diese
          Informationen werden bei jedem Aufruf unserer Internetseite
          automatisch erfasst und in unseren Serverlogfiles gespeichert. Diese
          Informationen beziehen sich auf das Computersystem des aufrufenden
          Rechners. Folgende Informationen werden dabei erhoben:
        </p>
        <ul class="ul1">
          <li class="li1">IP-Adresse</li>
          <li class="li1">
            Browsertyp/ -version (Bsp.: Firefox 59.0.2 (64 Bit))
          </li>
          <li class="li1">Browsersprache (Bsp.: Deutsch)</li>
          <li class="li1">Verwendetes Betriebssystem (Bsp.: Windows 10)</li>
          <li class="li1">Innere Aufl&ouml;sung des Browserfensters</li>
          <li class="li1">Bildschirmaufl&ouml;sung</li>
          <li class="li1">Javascriptaktivierung</li>
          <li class="li1">Java An / Aus</li>
          <li class="li1">Cookies An / Aus</li>
          <li class="li1">Farbtiefe</li>
          <li class="li1">Uhrzeit des Zugriffs</li>
        </ul>
        <p class="p2">
          Ferner setzen wir Cookies ein, um Ihnen unsere Webseite zur Nutzung
          zur Verf&uuml;gung zu stellen. Bei Cookies handelt es sich um
          Textdateien, die im Internetbrowser bzw. vom Internetbrowser bei dem
          Aufruf einer Website auf Ihrem Computersystem gespeichert werden. Ein
          Cookie enth&auml;lt eine charakteristische Zeichenfolge, die eine
          eindeutige Identifizierung des Browsers beim erneuten Aufrufen der
          Website erm&ouml;glicht. Wir setzen diese Cookies ausschlie&szlig;lich
          dazu ein, Ihnen unsere Webseite mit ihren technischen Funktionen zur
          Verf&uuml;gung zu stellen. Einige Funktionen unserer Website
          k&ouml;nnen ohne den Einsatz von Cookies nicht angeboten werden. In
          den Cookies werden dabei die folgenden Informationen gespeichert und
          an uns &uuml;bermittelt:
        </p>
        <p class="p2">
          <strong>Usercentrics Consent Manager</strong>
        </p>
        <p class="p2">
          Auf unserer Website benutzen wir den Consent Manager von Usercentrics
          GmbH, Rosental 4, 80331 M&uuml;nchen, Deutschland, Email:
          contact@usercentrics.com. Der Consent Manager von Usercentrics ist
          eine L&ouml;sung, mit der wir Ihre Einwilligung zu bestimmten,
          einwilligungsbed&uuml;rftigen Datenverarbeitungen (z. B. Tracking o.
          &Auml;.) einholen. Es informiert Sie &uuml;ber die einzelnen von uns
          eingesetzten Cookies und Tools und l&auml;sst Ihnen die Wahl, welche
          davon Sie individuell oder kategorisch zulassen oder ablehnen.
          Ebenfalls werden Sie dar&uuml;ber informiert, wann Sie welche
          Cookies/Tools zugelassen oder abgelehnt haben. Dies erm&ouml;glicht
          Ihnen eine informierte Entscheidung &uuml;ber die Weitergabe Ihrer
          Daten und uns einen datenschutzkonformen, transparenten und
          dokumentierten Einsatz von Cookies und Tools.
        </p>
        <p class="p1">
          Der Consent Manager speichert selbst keine personenbezogenen Daten von
          Ihnen. Es werden lediglich anonymisierte Daten generiert, die in einem
          Cookie gespeichert werden. Diese Daten werden ausschlie&szlig;lich in
          der europ&auml;ischen Union verarbeitet. Bei diesen Daten handelt es
          sich um folgende Informationen:
        </p>
        <ul class="ul1">
          <li class="li1">Datum und Uhrzeit Ihres Besuchs</li>
          <li class="li1">Informationen &uuml;ber das genutzte Ger&auml;t</li>
          <li class="li1">
            Informationen &uuml;ber den Browser, z. B. Browsertyp/ -version
          </li>
          <li class="li1">Ihre anonymisierte IP-Adresse</li>
          <li class="li1">Ihre Einwilligung oder Ablehnung von Cookies</li>
        </ul>
        <p class="p2">
          Weiterf&uuml;hrende Informationen sowie die Datenschutzerkl&auml;rung
          von Usercentrics finden Sie unter:{" "}
          <a href="https://usercentrics.com/privacy-policy/">
            <span class="s2">https://usercentrics.com/privacy-policy/</span>
          </a>
        </p>
        <p class="p1">
          Ihre Informationen, die wir durch vorstehende Cookies gesammelt haben,
          werden von uns nicht verwendet, um Nutzerprofile zu erstellen oder Ihr
          Surfverhalten auszuwerten. Wir verarbeiten Ihre personenbezogenen
          Daten zur technischen Bereitstellung unserer Website auf Grundlage der
          folgenden Rechtsgrundlagen:
        </p>
        <ul class="ul1">
          <li class="li1">
            zur Erf&uuml;llung eines Vertrags oder zur Durchf&uuml;hrung
            vorvertraglicher Ma&szlig;nahmen gem&auml;&szlig; Art. 6 Abs. 1 lit.
            b) DSGVO, soweit Sie unsere Website besuchen, um sich &uuml;ber
            unsere Produkte und unsere Veranstaltungen zu informieren
          </li>
          <li class="li1">
            zur Erf&uuml;llung einer rechtlichen Verpflichtung, der wir als
            Verantwortlicher unterliegen gem. Art. 6 Abs. 1 lit. c) DSGVO. Die
            rechtliche Verpflichtung liegt in der Einholung und Dokumentation
            Ihrer Einwilligung zur Datenverarbeitung.
          </li>
          <li class="li1">
            zur Wahrung unserer berechtigten Interessen gem&auml;&szlig; Art. 6
            Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse besteht dabei
            darin, Ihnen eine ansprechende, technisch funktionierende und
            benutzerfreundliche Website zur Verf&uuml;gung stellen zu
            k&ouml;nnen sowie um Ma&szlig;nahmen zum Schutz unserer Website vor
            Cyberrisiken zu ergreifen und zu verhindern, dass von unserer
            Website Cyberrisiken f&uuml;r Dritte ausgehen.
            <span class="Apple-converted-space">&nbsp;</span>
          </li>
        </ul>
        <p class="p1">
          1.1.2 Statistische Analyse der Nutzung der Website und
          Reichweitenerh&ouml;hung
        </p>
        <p class="p2">
          Zum Zweck der statistischen Analyse der Nutzung unserer Website setzen
          wir etracker, Google Analytics und Dynamic Yield und damit Cookies
          ein, die eine Analyse Ihres Surfverhaltens erm&ouml;glichen. Dadurch
          k&ouml;nnen wir die Qualit&auml;t unserer Website und ihrer Inhalte
          verbessern. Wir erfahren, wie die Website genutzt wird und k&ouml;nnen
          so unser Angebot stetig optimieren.
        </p>
        <p class="p1">
          Die im Rahmen der statistischen Analyse unserer Website erlangten
          Informationen werden nicht mit Ihren sonstigen, im Rahmen der Website
          erfassten Daten zusammengef&uuml;hrt.
        </p>
        <p class="p1">1.1.2.1 Google Tag Manager</p>
        <p class="p1">
          Auf unserer Webseite benutzen wir den Google Tag Manager. Der Google
          Tag Manager ist eine L&ouml;sung, mit der Vermarkter Webseiten-Tags
          &uuml;ber eine Oberfl&auml;che verwalten k&ouml;nnen. Der Dienst
          Google Tag Manager selbst (der die Tags implementiert) ist eine
          Cookie-lose Domain und erfasst keine personenbezogenen Daten. Der
          Dienst Google Tag Manager sorgt f&uuml;r die Ausl&ouml;sung anderer
          Tags, die ihrerseits unter Umst&auml;nden Daten erfassen. Google Tag
          Manager greift nicht auf diese Daten zu. Wenn auf Domain- oder
          Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese
          f&uuml;r alle Tracking-Tags bestehen, die mit Google Tag Manager
          implementiert werden.
        </p>
        <p class="p1">1.1.2.2 Tealium iQ Tag Management</p>
        <p class="p1">
          Auf unserer Website benutzen wir Tealium iQ Tag Management, von
          Tealium Inc., 11085 Torreyana Road, San Diego, CA, 92121 USA, Email:
          privacy@tealium.com. Tealium iQ Tag Management ist eine L&ouml;sung,
          mit der wir unseren Seiten jeweils ein Tag (englisch f&uuml;r
          Markierung) zuweisen und diese verwalten k&ouml;nnen. Mithilfe der
          Tags kann der Tag Manager f&uuml;r uns Analyse- und Tracking-Tools
          gezielt nur dort einsetzen, wo sie auch einen Sinn ergeben. Das Tag
          Management speichert selbst keine personenbezogenen Daten von Ihnen.
          Es werden lediglich anonymisierte Daten generiert, die in dem Cookie
          &bdquo;utag_main&ldquo; lokal im Browser gespeichert werden.
        </p>
        <p class="p1">
          1.1.2.3 Google Analytics
          <span class="Apple-converted-space">&nbsp;</span>
        </p>
        <p class="p2">
          Auf unserer Website benutzen wir Google Analytics, einen
          Webanalysedienst der Google Ireland Ltd. (Gordon House, Barrow Street,
          Dublin 4, Ireland; &bdquo;Google&ldquo;). Google Analytics verwendet
          sog. &bdquo;Cookies&ldquo;, Textdateien, die auf Ihrem Computer
          gespeichert werden und die eine Analyse dar&uuml;ber erm&ouml;glichen,
          wie Sie die Website nutzen. Die durch das Cookie erzeugten
          Informationen &uuml;ber Ihre Benutzung unserer Website werden in der
          Regel an einen Server von Google in den USA &uuml;bertragen und dort
          gespeichert. Auf unserer Website verwenden wir Google Analytics mit
          der Erweiterung &bdquo;_anonymizeIP()&ldquo;. Dadurch wird Ihre
          IP-Adresse von Google innerhalb von Mitgliedstaaten der
          Europ&auml;ischen Union oder in anderen Vertragsstaaten des Abkommens
          &uuml;ber den Europ&auml;ischen Wirtschaftsraum zuvor gek&uuml;rzt.
          Nur in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server
          von Google in den USA &uuml;bertragen und dort gek&uuml;rzt. Eine
          direkte Personenbeziehbarkeit kann damit ausgeschlossen werden. In
          unserem Auf-trag wird Google diese Informationen benutzen, um Ihre
          Nutzung der Website auszuwerten, um Reports &uuml;ber die
          Websiteaktivit&auml;ten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen
          gegen&uuml;ber dem Websitebetreiber zu erbringen. Die im Rahmen von
          Google Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse wird
          nicht mit anderen Daten von Google zusammengef&uuml;hrt.
        </p>
        <p class="p1">
          Wir setzen Google Analytics ausschlie&szlig;lich mit Ihrer
          Einwilligung ein. Sie k&ouml;nnen eine einmal erteilte Einwilligung
          jederzeit f&uuml;r die Zukunft widerrufen, indem Sie
        </p>
        <ul class="ul1">
          <li class="li1">
            die Speicherung der Cookies durch eine entsprechende Einstellung
            Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin,
            dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche
            Funktionen unser Website vollumf&auml;nglich werden nutzen
            k&ouml;nnen;
          </li>
          <li class="li1">
            das unter dem folgenden Link verf&uuml;gbare Browser-Plugin
            herunterladen und installieren:{" "}
            <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
              <span class="s2">
                http://tools.google.com/dlpage/gaoptout?hl=de
              </span>
            </a>{" "}
            oder
          </li>
          <li class="li1">
            &uuml;ber diesen Link den Consent Manager erneut aufrufen und unter
            der jeweiligen Kategorie das von Ihnen zur Einwilligung gesetzte
            H&auml;kchen wieder entfernen und anschlie&szlig;end die Einstellung
            best&auml;tigen.
          </li>
        </ul>
        <p class="p2">
          N&auml;here Informationen zu Nutzungsbedingungen und Datenschutz von
          bzw. bei Google Analytics finden Sie unter
          http://www.google.com/analytics/terms/de.html bzw. unter
          https://www.google.de/intl/de/policies/
        </p>
        <p class="p2">
          Die in Google Analytics erhobenen Daten werden 26 Monate nach Ihrem
          letzten Besuch auf der Webseite gel&ouml;scht.
        </p>
        <p class="p2">
          <strong>Demografische Merkmale bei Google Analytics</strong>
        </p>
        <p class="p1">
          Diese Website nutzt die Funktion &ldquo;demografische Merkmale&rdquo;
          von Google Analytics. Dadurch k&ouml;nnen Berichte erstellt werden,
          die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher
          enthalten. Diese Daten stammen aus interessenbezogener Werbung von
          Google sowie aus Besucherdaten von Drittanbietern. Diese Daten
          k&ouml;nnen keiner bestimmten Person zugeordnet werden. Sie
          k&ouml;nnen diese Funktion jederzeit &uuml;ber die
          Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die
          Erfassung Ihrer Daten durch folgenden Link unter der Option "Google
          AdWords Remarketing" untersagen.
        </p>
        <p class="p1">1.1.2.4 Google Adwords</p>
        <p class="p2">
          Wir nutzen das Online-Werbeprogramm &bdquo;Google AdWords&ldquo; und
          im Rahmen von Google AdWords das Conversion-Tracking. Das Google
          Conversion Tracking ist ein Analysedienst der Google Ireland Ltd.
          (Gordon House, Barrow Street, Dublin 4, Irland; &bdquo;Google&ldquo;).
          Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein
          Cookie f&uuml;r das Conversion-Tracking auf Ihrem Rechner abgelegt.
          Diese Cookies verlieren nach 30 Tagen ihre G&uuml;ltigkeit, enthalten
          keine personenbezogenen Daten und dienen somit nicht der
          pers&ouml;nlichen Identifizierung.
        </p>
        <p class="p2">
          Wenn Sie bestimmte Internetseiten vor unserer Website besuchen und das
          Cookie noch nicht abgelaufen ist, k&ouml;nnen Google und wir erkennen,
          dass Sie auf die Anzeige geklickt haben und zu dieser Seite
          weitergeleitet wurden. Jeder Google AdWords-Kunde erh&auml;lt ein
          anderes Cookie. Somit besteht keine M&ouml;glichkeit, dass Cookies
          &uuml;ber die Websites von AdWords-Kunden nachverfolgt werden
          k&ouml;nnen.
        </p>
        <p class="p2">
          Die Informationen, die mithilfe des Conversion-Cookie eingeholten
          werden, dienen dazu, Conversion-Statistiken f&uuml;r AdWords-Kunden zu
          erstellen, die sich f&uuml;r Conversion-Tracking entschieden haben.
          Hierbei erfahren die Kunden die Gesamtanzahl der Nutzer, die auf ihre
          Anzeige geklickt haben, zu einer mit einem Conversion-Tracking-Tag
          versehenen Seite weitergeleitet wurden und dort [&hellip; z.B. ein
          Produkt gekauft oder sich f&uuml;r einen Newsletter angemeldet haben].
          Sie erhalten jedoch keine Informationen, mit denen sich Nutzer
          pers&ouml;nlich identifizieren lassen.
        </p>
        <p class="p2">
          Wenn Sie nicht am Tracking teilnehmen m&ouml;chten, k&ouml;nnen Sie
          dieser Nutzung widersprechen, indem Sie die Installation der Cookies
          durch eine entsprechende Einstellung Ihrer Browser Software verhindern
          (Deaktivierungsm&ouml;glichkeit) oder die Erfassung Ihrer Daten durch
          folgenden Link untersagen. Sie werden dann nicht in die
          Conversion-Tracking Statistiken aufgenommen.
        </p>
        <p class="p2">
          In den{" "}
          <a href="https://adssettings.google.com/">
            <span class="s2">Einstellungen f&uuml;r Werbung</span>
          </a>{" "}
          k&ouml;nnen Sie jederzeit sehen und festlegen, welche Daten Google zum
          Einblenden von Werbung verwendet.
        </p>
        <p class="p1">
          Weiterf&uuml;hrende Informationen sowie die Datenschutzerkl&auml;rung
          von Google finden Sie unter:
        </p>
        <ul class="ul1">
          <li class="li3">
            <span class="s3">
              <a href="https://policies.google.com/technologies/ads?hl=de">
                <span class="s4">
                  http://www.google.com/policies/technologies/ads/
                </span>
              </a>
            </span>
          </li>
          <li class="li3">
            <span class="s3">
              <a href="https://policies.google.com/technologies/partner-sites">
                <span class="s4">
                  https://policies.google.com/technologies/partner-sites
                </span>
              </a>
            </span>
          </li>
          <li class="li3">
            <span class="s3">
              <a href="http://www.google.de/policies/privacy/">
                <span class="s4">http://www.google.de/policies/privacy/</span>
              </a>
            </span>
          </li>
        </ul>
        <p class="p1">1.1.2.5 Google Optimize</p>
        <p class="p2">
          Als Bestandteil von Google Analytics nutzen wir zur Optimierung
          unserer Website &bdquo;Google Optimize&ldquo;, ebenfalls einen Dienst
          der Google Google Ireland Limited, Gordon House,&nbsp; Barrow Street,
          Dublin 4, Ireland. Wir setzen diesen Dienst ein, um das individuelle
          Nutzererlebnis auf unserer Website zu verbessern. Dazu nutzen wir die
          durch Google Analytics gesetzten Cookies und erhobenen Informationen
          (s.o.), um verschiedenen Nutzergruppen individuelle zum
          Nutzerverhalten passende Funktionen und Ansichten der Seite zur
          Verf&uuml;gung zu stellen . Dar&uuml;ber hinaus stellen wir im Zuge
          von A/B-Testings unterschiedliche Auspr&auml;gungen der Website nicht
          vorher definierten Nutzergruppen bereit, um damit das Onlineangebot
          stetig zu verbessern.
        </p>
        <p class="p1">
          Wir setzen Google Optimize ausschlie&szlig;lich ein, wenn Sie Ihre
          Einwilligung zu Google Analytics (s.o.) erteilt haben. Dementsprechend
          k&ouml;nnen Sie durch einen Widerruf der Einwilligung in Google
          Analytics auch die Einwilligung in Google Optimize widerrufen.
        </p>
        <p class="p1">1.1.2.6 Google Remarketing</p>
        <p class="p1">
          Wir nutzen die Remarketing Technologie von Google. Durch diese
          Technologie werden Nutzer, die die unsere Internetseiten und
          Onlinedienste bereits besucht und sich f&uuml;r das Angebot
          interessiert haben, durch zielgerichtete Werbung auf den Seiten des
          Google Partner Netzwerks erneut angesprochen. Die Einblendung der
          Werbung erfolgt durch den Einsatz von Cookies. Mit Hilfe dieser
          Cookies kann das Nutzerverhalten beim Besuch der Website analysiert
          und anschlie&szlig;end f&uuml;r gezielte Produktempfehlungen und
          interessenbasierte Werbung genutzt werden. Wenn Sie keine
          interessenbasierte Werbung erhalten m&ouml;chten, k&ouml;nnen Sie die
          Verwendung von Cookies durch Google f&uuml;r diese Zwecke
          deaktivieren, indem sie diesen Link aufrufen.
        </p>
        <p class="p1">1.1.2.7 etracker</p>
        <p class="p2">
          Auf unserer Website werden mit Technologien der etracker GmbH, Erste
          Brunnenstra&szlig;e 1, 20459 Hamburg (www.etracker.com) Daten
          verarbeitet, aus denen unter Verwendung von Pseudonymen
          Nutzungsprofile erstellt werden. Diese Nutzungsprofile dienen der
          Analyse des Besucherverhaltens und werden zur Verbesserung und
          bedarfsgerechten Gestaltung unseres Angebots ausgewertet. Hierzu
          k&ouml;nnen Cookies eingesetzt werden (Erl&auml;uterung s.o.).
          etracker Cookies enthalten keine Informationen, die eine
          Identifikation eines Nutzers erm&ouml;glichen. Die pseudonymisierten
          Nutzungsprofile werden nicht dazu benutzt, den Besucher dieser Website
          pers&ouml;nlich zu identifizieren und nicht mit personenbezogenen
          Daten &uuml;ber den Tr&auml;ger des Pseudonyms zusammengef&uuml;hrt.
        </p>
        <p class="p2">
          Wir setzen eTracker nur mit Ihrer Einwilligung ein. Eine einmal
          erteilte Einwilligung k&ouml;nnen Sie jederzeit f&uuml;r die Zukunft
          widerrufen, &uuml;ber diesen Link den Consent Manager erneut aufrufen
          und unter der jeweiligen Kategorie das von Ihnen zur Einwilligung
          gesetzte H&auml;kchen wieder entfernen und anschlie&szlig;end die
          Einstellung best&auml;tigen.
        </p>
        <p class="p1">
          Weitere Informationen zum Datenschutz bei etracker finden Sie{" "}
          <a href="https://www.etracker.com/datenschutz/">
            <span class="s2">hier.</span>
          </a>
        </p>
        <p class="p1">1.1.3 Aktive Nutzung der Website</p>
        <p class="p1">
          Neben der rein informatorischen Nutzung unserer Website k&ouml;nnen
          Sie unsere Website auch aktiv nutzen, um einen Versicherungsvertrag
          bei uns abzuschlie&szlig;en, sich f&uuml;r eine Veranstaltung
          anzumelden, sich f&uuml;r unseren Newsletter zu registrieren oder um
          mit uns in Kontakt zu treten. Zus&auml;tzlich zu der oben
          dargestellten Verarbeitung Ihrer personenbezogenen Daten bei einer
          rein informatorischen Nutzung verarbeiten wir dann auch weitere
          personenbezogene Daten von Ihnen, die wir zur Durchf&uuml;hrung Ihres
          Vertrages bzw. zur Bearbeitung und Beantwortung Ihrer Anfrage
          ben&ouml;tigen.
        </p>
        <p class="p1">1.1.3.1 Nutzeranfragen</p>
        <p class="p2">
          Um Ihre Anfragen an uns, z.B. &uuml;ber das Kontaktformular oder an
          unsere E-Mail-Adresse, bearbeiten und beantworten zu k&ouml;nnen,
          verarbeiten wir die von Ihnen in diesem Zusammenhang mitgeteilten
          personenbezogenen Daten. Dazu z&auml;hlen in jedem Fall Ihr Name und
          Ihre E-Mail-Adresse, um Ihnen eine Antwort zukommen zu lassen, sowie
          die sonstigen Informationen, die Sie uns im Rahmen Ihrer Mitteilung
          zusenden.
        </p>
        <p class="p1">
          Wir verarbeiten Ihre personenbezogenen Daten zur Beantwortung von
          Nutzeranfragen auf folgender Rechtsgrundlage:
        </p>
        <ul class="ul1">
          <li class="li1">
            Die Verarbeitung ist erforderlich f&uuml;r die Erf&uuml;llung eines
            Vertrages oder zur Durchf&uuml;hrung vorvertraglicher
            Ma&szlig;nahmen gem. Art. 6 Abs. 1 lit. b) DSGVO;
          </li>
        </ul>
        <p class="p1">1.1.3.3 Angebotsabgabe</p>
        <p class="p1">
          Wenn Sie im Rahmen unserer Website eine unserer
          Versicherungsleistungen beantragen m&ouml;chten, verarbeiten wir Ihre
          personenbezogenen Daten, um den Antrag im Rahmen der Website
          entgegenzunehmen, abzuwickeln und Ihnen die Versicherungsleistungen
          bereitstellen zu k&ouml;nnen. Dabei verarbeiten wir die aus den
          jeweiligen Eingabeformularen ersichtlichen Angaben (freiwillige
          Angaben sind entsprechend gekennzeichnet):
        </p>
        <ul class="ul1">
          <li class="li1">E-Mail Adresse</li>
          <li class="li1">Anrede</li>
          <li class="li1">Position</li>
          <li class="li1">Vor- und Nachname</li>
          <li class="li1">Stra&szlig;e/Hausnummer</li>
          <li class="li1">Postleitzahl</li>
          <li class="li1">Stadt</li>
          <li class="li1">Land</li>
          <li class="li1">Telefon</li>
          <li class="li1">
            Geburtsdatum (sofern zur Unterbreitung eines Angebots erforderlich)
          </li>
        </ul>
        <p class="p1">
          Wir verarbeiten Ihre Daten f&uuml;r die obigen Zwecke auf Basis der
          folgenden Rechtsgrundlagen:
        </p>
        <ul class="ul1">
          <li class="li1">
            zur Erf&uuml;llung eines Vertrags oder zur Durchf&uuml;hrung
            vorvertraglicher Ma&szlig;nahmen gem&auml;&szlig; Art. 6 Abs. 1 lit.
            b) DSGVO, soweit Sie unser Portal nutzen, um sich &uuml;ber unser
            Angebot zu informieren und Versicherungsvertr&auml;ge
            abzuschlie&szlig;en;
          </li>
          <li class="li1">
            sofern es sich um ein dem Kontrahierungszwang unterliegendes
            Versicherungsangebot handelt gem. Art. 6 Abs. 1 lit. c) DSGVO
          </li>
        </ul>
        <p class="p1">1.1.3.4 Bonit&auml;tspr&uuml;fung</p>
        <p class="p2">
          Sofern wir in Vorleistung treten, behalten wir uns vor, zur Wahrung
          unserer berechtigten Interessen ggf. eine Bonit&auml;tsauskunft auf
          der Basis mathematisch-statistischer Verfahren bei folgendem(n)
          Unternehmen einzuholen:
        </p>
        <p class="p2">
          Infoscore Consumer Data GmbH, Rheinstra&szlig;e 99 76532 Baden Baden
        </p>
        <p class="p2">
          Hierzu &uuml;bermitteln wir die zu einer Bonit&auml;tspr&uuml;fung
          ben&ouml;tigten, von Ihnen angegebenen, personenbezogenen Daten und
          verwenden die erhaltenen Informationen &uuml;ber die statistische
          Wahrscheinlichkeit eines Zahlungsausfalls. Die Bonit&auml;tsauskunft
          kann Wahrscheinlichkeitswerte (Score-Werte) beinhalten, die auf Basis
          wissenschaftlich anerkannter mathematisch-statistischer Verfahren
          berechnet werden. Dabei wird mittels einer Vielzahl von Merkmalen, wie
          beispielsweise Einkommen, Anschriftendaten, Beruf, Familienstand und
          bisherigem Zahlungsverhalten, auf das zuk&uuml;nftige
          Zahlungsausfallrisiko des Kunden geschlossen. Das Ergebnis wird in
          Form eines Zahlungswertes (sog. Score) ausgedr&uuml;ckt. Die so
          erhaltenen Informationen sind Basis unserer Entscheidung &uuml;ber die
          Begr&uuml;ndung, Durchf&uuml;hrung oder Beendigung des
          Vertragsverh&auml;ltnisses. Die Auswahlm&ouml;glichkeit einer der
          angebotenen Zahlungsarten ist jedoch nicht abh&auml;ngig von
          derartigen Informationen.
        </p>
        <p class="p1">
          Wir verarbeiten Ihre personenbezogenen Daten zur
          Bonit&auml;tspr&uuml;fung auf Grundlage der folgenden
          Rechtsgrundlagen:
        </p>
        <ul class="ul1">
          <li class="li1">
            zur Erf&uuml;llung eines Vertrags oder zur Durchf&uuml;hrung
            vorvertraglicher Ma&szlig;nahmen gem&auml;&szlig; Art. 6 Abs. 1 lit.
            b) DSGVO;
          </li>
          <li class="li1">
            zur Wahrung unserer berechtigten Interessen gem&auml;&szlig; Art. 6
            Abs. 1 lit. f) DSGVO, soweit wir rechtliche Anspr&uuml;che geltend
            machen oder uns bei rechtlichen Streitigkeiten verteidigen oder wir
            Straftaten verhindern oder aufkl&auml;ren.
          </li>
        </ul>
        <p class="p1">1.1.3.5 Zahlung</p>
        <p class="p1">
          Zur Zahlungsabwicklung ihrer Versicherungsvertrages setzen wir einen
          Zahlungsdienstleister ein. Wir verarbeiten Ihre personenbezogenen
          Daten zur Durchf&uuml;hrung des Vertrages auf unserer Website auf
          Grundlage der folgenden Rechtsgrundlagen:
        </p>
        <ul class="ul1">
          <li class="li1">
            zur Erf&uuml;llung eines Vertrags oder zur Durchf&uuml;hrung
            vorvertraglicher Ma&szlig;nahmen gem&auml;&szlig; Art. 6 Abs. 1 lit.
            b DSGVO.
          </li>
        </ul>
        <p class="p1">1.1.3.6 Einhaltung gesetzlicher Vorschriften</p>
        <p class="p2">
          Wir verarbeiten Ihre personenbezogenen Daten zudem, um sonstige
          gesetzliche Pflichten, die uns im Zusammenhang mit der
          Durchf&uuml;hrung des Vertrages treffen, zu erf&uuml;llen. Hierzu
          z&auml;hlen insbesondere handels-, gewerbe- oder steuerrechtliche
          Aufbewahrungsfristen.
        </p>
        <p class="p1">
          Wir verarbeiten Ihre personenbezogenen Daten dabei aufgrund der
          folgenden Rechtsgrundlagen:
        </p>
        <ul class="ul1">
          <li class="li1">
            zur Erf&uuml;llung einer rechtlichen Verpflichtung, der wir
            unterliegen gem&auml;&szlig; Art. 6 Abs. 1 lit. c) DSGVO in
            Verbindung mit Handels-, Gewerbe, oder Steuerrecht, soweit wir dazu
            verpflichtet sind, Ihre Daten aufzuzeichnen und aufzubewahren.
          </li>
        </ul>
        <p class="p1">1.1.3.7 Rechtsdurchsetzung</p>
        <p class="p2">
          Wir verarbeiten Ihre personenbezogenen Daten zudem, um unsere Rechte
          geltend zu machen und unsere rechtlichen Anspr&uuml;che durchsetzen zu
          k&ouml;nnen. Ebenfalls verarbeiten wir Ihre personenbezogenen Daten,
          um uns gegen rechtliche Anspr&uuml;che verteidigen zu k&ouml;nnen.
          Schlie&szlig;lich verarbeiten wir Ihre personenbezogenen Daten, soweit
          dies zur Abwehr oder Verfolgung von Straftaten erforderlich ist.
        </p>
        <p class="p1">
          Wir verarbeiten Ihre personenbezogenen Daten zu diesem Zwecke auf
          Grundlage der folgenden Rechtsgrundlagen:
        </p>
        <ul class="ul1">
          <li class="li1">
            zur Wahrung unserer berechtigten Interessen gem&auml;&szlig; Art. 6
            Abs. 1 lit. f) DSGVO, soweit wir rechtliche Anspr&uuml;che geltend
            machen oder uns bei rechtlichen Streitigkeiten verteidigen oder wir
            Straftaten verhindern oder aufkl&auml;ren.
          </li>
        </ul>
        <p class="p1">
          1.1.3.8 Datenverwendung f&uuml;r E-Mail-Werbung und Ihr
          Widerspruchsrecht
        </p>
        <p class="p2">
          Wenn wir Ihre E-Mail-Adresse im Zusammenhang mit dem Vertragsschluss
          und der Bereitstellung unserer Produkte erhalten und Sie dem nicht
          widersprochen haben, behalten wir uns vor, Ihnen regelm&auml;&szlig;ig
          Angebote zu &auml;hnlichen Produkten aus unserem Angebot per E-Mail
          zuzusenden. Sie k&ouml;nnen dieser Verwendung Ihrer E-Mail-Adresse
          jederzeit durch eine Nachricht an die unten beschriebene
          Kontaktm&ouml;glichkeit oder &uuml;ber einen daf&uuml;r vorgesehenen
          Link in der Werbemail f&uuml;r die Zukunft widersprechen, ohne dass
          hierf&uuml;r andere als die &Uuml;bermittlungskosten nach den
          Basistarifen entstehen.
        </p>
        <p class="p4">&nbsp;</p>
        <p class="p1">2. Links</p>
        <p class="p1">
          <br /> <br /> <strong>Verwendung von Kartendiensten</strong>
        </p>
        <p class="p1">2.2 Google Maps</p>
        <p class="p2">
          Auf unserer Website ist f&uuml;r Sie Google Maps eingebunden, ein
          Dienst der Google, Inc.
        </p>
        <p class="p1">
          Um den Schutz Ihrer Daten beim Besuch unserer Website zu erh&ouml;hen,
          ist Google Maps eingeschr&auml;nkt und lediglich unter Verwendung
          eines HTML-Links in die Seite eingebunden.
          <br /> <br /> Dadurch wird gew&auml;hrleistet, dass beim Aufruf
          unserer Website noch keine Verbindung mit den Servern von Google
          hergestellt und ihre Daten nicht an Google &uuml;bermittelt werden.
          Erst wenn Sie die Plugins aktivieren und damit Ihre Zustimmung zur
          Daten&uuml;bermittlung erteilen, stellt Ihr Browser eine direkte
          Verbindung zu den Servern von Google her, damit Sie Ihre Route zu uns
          planen k&ouml;nnen. Funktional entspricht die Einbindung von Google
          Maps damit einem Hyperlink, sodass weder durch uns, noch durch Google,
          auf unserer Website Daten von Ihnen erhoben werden.
          <br /> <br /> Zweck und Umfang der Datenerhebung und die weitere
          Verarbeitung und Nutzung der Daten durch Google sowie Ihre
          diesbez&uuml;glichen Rechte und Einstellungsm&ouml;glichkeiten zum
          Schutz Ihrer Privatsph&auml;re entnehmen Sie bitte den
          Datenschutzhinweisen von Google unter{" "}
          <a href="https://www.google.com/policies/privacy/partners/?hl=de">
            <span class="s2">
              https://www.google.com/policies/privacy/partners/?hl=de
            </span>
          </a>
        </p>
        <p class="p1">2.3 HERE Maps</p>
        <p class="p2">
          Wir nutzen auf unserer Website den Kartendienst HERE. Anbieter ist
          HERE Global B.V. , Kennedyplein 222-226, 5611 ZT Eindhoven,
          Niederlande.
          <br /> <br /> Bei der Anzeige von Kartenmaterial des Kartendienstes
          HERE auf der Webseite wird eine Verbindung mit dem Server von HERE
          hergestellt, um Ihnen Gesch&auml;ftsstellen und Vermittler in Ihrer
          N&auml;he anzeigen zu k&ouml;nnen. Dabei wird Ihre IP-Adresse zwecks
          Anzeige des Kartenmaterials an HERE &uuml;bertragen und dort
          gespeichert. Wir als Webseitenbetreiber haben darauf keinen Einfluss.
          <br /> <br /> Die Datenverarbeitung erfolgt auf der Rechtsgrundlage
          des Art. 6 Abs .1 lit b) DSGVO. Sie dient der Durchf&uuml;hrung
          vorvertraglicher Ma&szlig;nahmen, die auf Ihre Suchanfrage hin durch
          die Nutzung der Beratersuche und Anzeige von Gesch&auml;ftsstellen und
          Beratern erfolgen.
          <br /> <br /> Mehr Informationen zum Umgang mit Ihren Nutzerdaten
          finden Sie in der Datenschutzerkl&auml;rung von HERE:{" "}
          <a href="https://legal.here.com/privacy/policy">
            <span class="s2">https://legal.here.com/privacy/policy</span>
          </a>
          .
          <strong>
            <span class="Apple-converted-space">&nbsp;</span>
          </strong>
        </p>
        <p class="p1">&nbsp;</p>
        <p class="p1">3. Kategorien von Empf&auml;ngern</p>
        <p class="p2">
          Zun&auml;chst erhalten nur unsere Mitarbeiter Kenntnis von Ihren
          personenbezogenen Daten. Zudem teilen wir Ihre personenbezogenen
          Daten, soweit dies gesetzlich erlaubt oder vorgeschrieben ist, anderen
          Empf&auml;ngern mit, die f&uuml;r uns Dienstleistungen im Zusammenhang
          mit unserer Website erbringen. Wir beschr&auml;nken die Weitergabe
          Ihrer personenbezogener Daten dabei auf das Notwendige, insbesondere
          um Ihren Versicherungsvertrag durchf&uuml;hren zu k&ouml;nnen.
          Teilweise erhalten unsere Dienstleister Ihre personenbezogenen Daten
          als Auftragsverarbeiter und sind dann bei dem Umgang mit Ihren
          personenbezogenen Daten streng an unsere Weisungen gebunden. Teilweise
          agieren die Empf&auml;nger eigenst&auml;ndig mit Ihren Daten, die wir
          an diese &uuml;bermitteln.
        </p>
        <p class="p1">
          Nachfolgend nennen wir Ihnen die Kategorien der Empf&auml;nger Ihrer
          personenbezogenen Daten:
        </p>
        <ul class="ul1">
          <li class="li1">
            Ggf. Zahlungsdienstleister und Banken, bei der Abwicklung der
            Zahlung,
          </li>
          <li class="li1">
            Externe Dienstleister f&uuml;r den Versand per Post
          </li>
          <li class="li1">
            Dienstleister zum personalisierten Versand von Newslettern
          </li>
          <li class="li1">
            IT-Dienstleister bei der Administration und dem Hosting unserer
            Website,
          </li>
          <li class="li1">
            Inkassounternehmen und Rechtsberater bei der Geltendmachung unserer
            Anspr&uuml;che
          </li>
          <li class="li1">Ggfs. weitere Empf&auml;ngerkategorien.</li>
        </ul>
        <p class="p1">&nbsp;</p>
        <p class="p1">5. Dauer der Speicherung</p>
        <p class="p1">5.1 Informatorische Nutzung der Website</p>
        <p class="p2">
          Bei der rein informatorischen Nutzung unserer Website speichern wir
          Ihre personenbezogen Daten auf unseren Servern ausschlie&szlig;lich
          f&uuml;r die Dauer des Besuchs unserer Website. Nachdem Sie unsere
          Website verlassen haben, werden Ihre personenbezogenen Daten
          unverz&uuml;glich gel&ouml;scht.
        </p>
        <p class="p2">
          Von uns installierte Cookies werden in der Regel ebenfalls nach dem
          Verlassen unserer Website gel&ouml;scht. Dies gilt jedoch nicht
          f&uuml;r Cookies von Google, etracker und Dynamic Yield. Diese bleiben
          je nach Cookie-Kategorie f&uuml;r die Dauer von 7 Tagen bis 38 Monaten
          gespeichert. Zudem haben Sie jederzeit die M&ouml;glichkeit,{" "}
          <a href="https://www.google.com/search?q=cookies+l%25C3%25B6schen">
            <span class="s2">installierte Cookies selbst zu l&ouml;schen</span>
          </a>
          .
        </p>
        <p class="p1">
          Der Consent-Manager von Usercentrics bewahrt einen Nachweis &uuml;ber
          den Widerruf einer zuvor erteilten Einwilligung f&uuml;r einen
          Zeitraum von drei Jahren auf.
        </p>
        <p class="p1">5.2 Aktive Nutzung der Website</p>
        <p class="p2">
          Bei einer aktiven Nutzung unserer Website speichern wir Ihre
          personenbezogenen Daten zun&auml;chst f&uuml;r die Dauer der
          Beantwortung Ihrer Anfrage bzw. f&uuml;r die Dauer unserer
          Gesch&auml;ftsbeziehung. Das schlie&szlig;t auch die Anbahnung eines
          Vertrages (vorvertragliches Rechtsverh&auml;ltnis) und die Abwicklung
          eines Vertrages mit ein.
        </p>
        <p class="p2">
          Zus&auml;tzlich speichern wir Ihre personenbezogenen Daten sodann bis
          zum Eintritt der Verj&auml;hrung etwaiger rechtlicher Anspr&uuml;che
          aus der Beziehung mit Ihnen, um sie gegebenenfalls als Beweismittel
          einzusetzen. Die Verj&auml;hrungsfrist betr&auml;gt in der Regel
          zwischen 12 und 36 Monaten, kann aber auch bis zu 30 Jahre betragen.
        </p>
        <p class="p5">
          Mit Eintritt der Verj&auml;hrung l&ouml;schen wir Ihre
          personenbezogenen Daten, es sei denn, es liegt eine gesetzliche
          Aufbewahrungspflicht, zum Beispiel aus dem Handelsgesetzbuch,
          Versicherungsvertragsgesetz, Kreditwesengesetz und
          Geldw&auml;schegesetz oder aus der Abgabenordnung vor. Diese
          Aufbewahrungspflichten k&ouml;nnen zwei bis zehn Jahre betragen.
        </p>
        <p class="p6">&nbsp;</p>
        <p class="p7">&nbsp;</p>
        <p class="p7">&nbsp;</p>
        <p class="p7">&nbsp;</p>
        <p class="p6">6. Ihre Rechte als betroffene Person</p>
        <p class="p2">
          Ihnen stehen unter den gesetzlichen Voraussetzungen die nachfolgenden
          Rechte als betroffene Person zu, die Sie uns gegen&uuml;ber geltend
          machen k&ouml;nnen:
        </p>
        <p class="p2">
          <strong>Auskunft:</strong> Sie sind jederzeit berechtigt, im Rahmen
          von Art. 15 DSGVO von uns eine Best&auml;tigung dar&uuml;ber zu
          verlangen, ob wir Sie betreffende personenbezogene Daten verarbeiten;
          ist dies der Fall, sind Sie im Rahmen von Art. 15 DSGVO ferner
          berechtigt, Auskunft &uuml;ber diese personenbezogenen Daten sowie
          bestimmte weitere Informationen (u.a. Verarbeitungszwecke, Kategorien
          personenbezogener Daten, Kategorien von Empf&auml;ngern, geplante
          Speicherdauer, die Herkunft der Daten, den Einsatz einer
          automatisierten Entscheidungsfindung und im Fall des Drittlandtransfer
          die geeigneten Garantien) und eine Kopie Ihrer Daten zu erhalten.
        </p>
        <p class="p2">
          <strong>Berichtigung:</strong> Sie sind berechtigt, nach Art. 16 DSGVO
          von uns zu verlangen, dass wir die &uuml;ber Sie gespeicherten
          personenbezogenen Daten berichtigen, wenn diese unzutreffend oder
          fehlerhaft sind.
        </p>
        <p class="p2">
          <strong>L&ouml;schung:</strong> Sie sind berechtigt, unter den
          Voraussetzungen von Art. 17 DSGVO von uns zu verlangen, dass wir Sie
          betreffende personenbezogene Daten unverz&uuml;glich l&ouml;schen. Das
          Recht auf L&ouml;schung besteht u.a. nicht, wenn die Verarbeitung der
          personenbezogenen Daten erforderlich ist f&uuml;r (i) die
          Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und
          Information, (ii) zur Erf&uuml;llung einer rechtlichen Verpflichtung,
          der wir unterliegen (z. B. gesetzliche Aufbewahrungspflichten) oder
          (iii) zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
          Rechtsanspr&uuml;chen.
        </p>
        <p class="p2">
          <strong>Einschr&auml;nkung der Verarbeitung:</strong> Sie sind
          berechtigt, unter den Voraussetzungen von Art. 18 DSGVO von uns zu
          verlangen, dass wir die Verarbeitung Ihrer personenbezogener Daten
          einschr&auml;nken.
        </p>
        <p class="p2">
          <strong>Daten&uuml;bertragbarkeit:</strong> Sie sind berechtigt, unter
          den Voraussetzungen von Art. 20 DSGVO von uns zu verlangen, dass wir
          Ihnen die Sie betreffenden personenbezogenen Daten, die Sie uns
          bereitgestellt haben, in einem strukturierten, g&auml;ngigen und
          maschinenlesbaren Format &uuml;bergeben oder an einen Dritten zu
          &uuml;bermitteln.
        </p>
        <p class="p2">
          <strong>Widerruf:</strong> Sie haben das Recht, Ihre erteilte
          Einwilligung in die Verarbeitung personenbezogener Daten jederzeit mit
          Wirkung f&uuml;r die Zukunft zu widerrufen (Art. 7 Abs. 3 DSGVO)
        </p>
        <p class="p2">
          <strong>Widerspruch:</strong> Sie sind berechtigt, unter den
          Voraussetzungen von Art. 21 DSGVO Widerspruch gegen die Verarbeitung
          Ihrer personenbezogenen Daten einzulegen, so dass wir die Verarbeitung
          Ihrer personenbezogenen Daten beenden m&uuml;ssen. Das
          Widerspruchsrecht besteht nur in den in Art. 21 DSGVO vorgesehen
          Grenzen. Zudem k&ouml;nnen unsere Interessen einer Beendigung der
          Verarbeitung entgegenstehen, so dass wir trotz Ihres Widerspruchs
          berechtigt sind, Ihre personenbezogenen Daten zu verarbeiten.
        </p>
        <p class="p2">
          <strong>
            Beschwerde bei einer Datenschutz-Aufsichtsbeh&ouml;rde:
          </strong>{" "}
          Sie sind berechtigt, unter den Voraussetzungen von Art. 77 DSGVO
          Beschwerde bei der Aufsichtsbeh&ouml;rde einzulegen, insbesondere in
          dem Mitgliedstaat ihres Aufenthaltsorts oder des Orts des
          mutma&szlig;lichen Versto&szlig;es, zu, wenn Sie der Ansicht sind,
          dass die Verarbeitung der Sie betreffenden personenbezogenen Daten
          gegen die DSGVO verst&ouml;&szlig;t. Das Beschwerderecht besteht
          unbeschadet eines anderweitigen verwaltungsrechtlichen oder
          gerichtlichen Rechtsbehelfs.
        </p>
        <p class="p2">
          <strong>
            F&uuml;r die Feuersoziet&auml;t Berlin ist die zust&auml;ndige
            Aufsichtsbeh&ouml;rde:
            <br /> Berliner Beauftragte f&uuml;r Datenschutz und
            Informationsfreiheit
            <br />{" "}
          </strong>
          Friedrichstra&szlig;e 219
          <br /> 10969 Berlin
          <br /> E-Mail:{" "}
          <a href="mailto:mailbox@datenschutz-berlin.de">
            <span class="s2">mailbox@datenschutz-berlin.de </span>
          </a>
          <a href="mailto:%2520mailbox@datenschutz-berlin.de">
            <span class="s2">
              <br /> <br />{" "}
            </span>
          </a>
          <strong>
            F&uuml;r die &ouml;ffentliche Berlin Leben ist die zust&auml;ndige
            Aufsichtsbeh&ouml;rde:
            <br /> Die Landesbeauftragte f&uuml;r den Datenschutz und f&uuml;r
            das Recht auf Akteneinsicht
          </strong>
          <br /> Stahnsdorfer Damm 77
          <br /> 14532 Kleinmachnow
          <br /> E-Mail:{" "}
          <a href="mailto:poststelle@ida.brandenburg.de">
            <span class="s2">
              poststelle@ida.brandenburg.de
              <span class="Apple-converted-space">&nbsp;</span>
            </span>
          </a>
        </p>
        <p class="p2">
          <br /> Wir empfehlen Ihnen allerdings, sich mit Ihrem Anliegen
          zun&auml;chst immer an unseren Datenschutzbeauftragten zu wenden.
        </p>
        <p class="p2">
          Ihre Antr&auml;ge &uuml;ber die Aus&uuml;bung Ihrer Rechte sollten
          nach M&ouml;glichkeit in Textform an folgende Anschrift gerichtet
          werden:<span class="Apple-converted-space">&nbsp;</span>
        </p>
        <p class="p2">&nbsp;</p>
        <p class="p2">
          <strong>Umfang Ihrer Pflichten zur Bereitstellung von Daten</strong>
        </p>
        <p class="p2">
          Grunds&auml;tzlich sind Sie nicht verpflichtet, uns Ihre
          personenbezogenen Daten mitzuteilen. Wenn Sie dies jedoch nicht tun,
          werden wir Ihnen unsere Website nicht zur Verf&uuml;gung stellen
          k&ouml;nnen, Ihre Anfragen an uns nicht beantworten k&ouml;nnen und
          mit Ihnen keinen Vertrag eingehen k&ouml;nnen. Personenbezogene Daten,
          die wir nicht zwingend f&uuml;r die oben genannten Verarbeitungszwecke
          ben&ouml;tigen, sind durch ein &bdquo;ggfs.&ldquo; oder ein anderes
          Zeichen als freiwillige Angaben gekennzeichnet.
        </p>
        <p class="p4">&nbsp;</p>
        <p class="p1">7. Automatisierte Entscheidungsfindung / Profiling</p>
        <p class="p1">
          Falls wir im Einzelfall rein automatisierte Verarbeitungsprozesse zur
          Herbeif&uuml;hrung einer Entscheidung &ndash; einschlie&szlig;lich
          Profiling &ndash; nutzen, werden wir dar&uuml;ber bei der jeweiligen
          Anwendung informieren.
        </p>
        <p class="p1">
          7.1 Information &uuml;ber Ihr Widerspruchsrecht Art. 21 DSGVO
        </p>
        <p class="p2">
          Sie haben das Recht, jederzeit gegen die Verarbeitung Ihrer Daten, die
          aufgrund von Art. 6 Abs. 1 f) DSGVO (Datenverarbeitung auf der
          Grundlage einer Interessenabw&auml;gung) oder Art. 6 Abs. 1 e) DSGVO
          (Datenverarbeitung im &ouml;ffentlichen Interesse) erfolgt,
          Widerspruch einzulegen, wenn daf&uuml;r Gr&uuml;nde vorliegen, die
          sich aus Ihrer besonderen Situation ergeben. Dies gilt auch f&uuml;r
          ein auf diese Bestimmung gest&uuml;tztes Profiling im Sinne von Art. 4
          Nr. 4 DSGVO.
        </p>
        <p class="p2">
          Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten
          nicht mehr verarbeiten, es sei denn, wir k&ouml;nnen zwingende
          schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen,
          die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen, oder die
          Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung
          von Rechtsanspr&uuml;chen.
        </p>
        <p class="p2">
          Wir verarbeiten Ihre personenbezogenen Daten in Einzelf&auml;llen
          auch, um Direktwerbung zu betreiben. Sofern Sie keine Werbung erhalten
          m&ouml;chten, haben Sie jederzeit das Recht, Widerspruch dagegen
          einzulegen; dies gilt auch f&uuml;r das Profiling, soweit es mit
          solcher Direktwerbung in Verbindung steht. Diesen Widerspruch werden
          wir f&uuml;r die Zukunft beachten.
        </p>
        <p class="p5">
          Ihre Daten werden wir nicht mehr f&uuml;r Zwecke der Direktwerbung
          verarbeiten, wenn Sie der Verarbeitung f&uuml;r diese Zwecke
          widersprechen.
        </p>
        <p class="p1">8. &Auml;nderungen</p>
        <p class="p2">
          Wie behalten uns das Recht vor, diese Datenschutzerkl&auml;rung
          jederzeit zu &auml;ndern. Etwaige &Auml;nderungen werden durch
          Ver&ouml;ffentlichung der ge&auml;nderten Datenschutzerkl&auml;rung
          auf unserer Webseite bekannt gemacht. Soweit nicht ein anderes
          bestimmt ist, werden solche &Auml;nderungen sofort wirksam. Bitte
          pr&uuml;fen Sie daher diese Datenschutzerkl&auml;rung
          regelm&auml;&szlig;ig, um die jeweils aktuellste Version einzusehen.
        </p>
      </div>
    </Content>
  );
}
