import React from "react";
import AirbrakeClient from "airbrake-js";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    if (process.env.NODE_ENV === "production") {
      this.airbrake = new AirbrakeClient({
        projectId: 202874,
        projectKey: "42b8e42732ada837cd345221ab14e69f",
        environment: process.env.REACT_APP_AIRBRAKE_ENV
          ? process.env.REACT_APP_AIRBRAKE_ENV
          : "production"
      });
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    if (process.env.NODE_ENV === "production") {
      // Send error to Airbrake
      this.airbrake.notify({
        error: error,
        params: { info: info }
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="container text-center p-4">
          <h4 className="text-muted">
            Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
          </h4>
          <button
            className="btn btn-lg btn-info m-4"
            type="button"
            onClick={() => window.location.reload()}
          >
            Aktualisieren
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}
