import React from 'react';
import styled from '@emotion/styled';

const Content = styled.div`
  overflow-y: auto;
  height: 100%;
  margin-right: 25px;
`

export default function Impressum() {
  return (
    <Content>
    <div className="col-12">
      <h3>Impressum</h3>
        <br/>
      <h4>Generalagentur Matthias Voss</h4>
      <h5>der Feuersozietät Berlin Brandenburg</h5>
      <address>
        Landgrafenstraße 15<br/>
        10787 Berlin<br/><br/>
        Telefon: <a href="tel:+493020913790" title="Anrufen Telefon">+49 30 20913790</a><br/>
        Telefax: <a href="fax:+4930209137922" title="Anrufen Fax">+49 30 209137922</a><br/>
        E-Mail: <a href="mailto:Matthias.Voss@Feuersozietaet.de" target="_blank" rel="noopener noreferrer">Matthias.Voss@Feuersozietaet.de</a>
      </address>
      <p>
        Gemeldet bei der zuständigen Behörde als gebundener Versicherungsvertreter gem. § 34d Abs. 7
        </p>
      <br/>
      <h5>Versicherungsvermittlerregister</h5>
      <address>
        Industrie- und Handelskammertag (DIHK) e.V.<br/>
        Breite Straße 29<br/>
        10178 Berlin<br/><br/>
        Telefon: <a href="tel:01806005850" title="Anrufen Telefon">0180 600 58 50</a><br/>
      </address>
      <small>(14 Cent/Minute aus dem deutschen Festnetz, max. 42 Cent/Minute aus dem Mobilfunknetz)</small>

      <p>
        unter der Registrierungsnummer <b>D-DNNJ-MGMRH-41</b>
      </p>

      <br/>
      <h5>Umsatzsteuer-Identifikationsnummer</h5>
      <p><b>DE282425089</b></p>
    </div>
    </Content>
  )
}
