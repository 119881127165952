import React from "react";

const beraterAvatarUrl =
  "https://www.feuersozietaet.de/export/sites/fbb/aussendienst/m/matthias_voss/_resources/bilder/profilbild.jpg";
const styles = {
  beraterAvatar: {
    width: 80,
    marginRight: 20,
  },
};

function BeraterInfo() {
  return (
    <div className="col-12 p-2">
      <div className="text-left">
        <h5 style={{ color: "#de2920", fontSize: "90%", fontWeight: "bold" }}>
          Ihr Berater vor Ort:
        </h5>
      </div>
      <div className="row text-left">
        <img
          src={beraterAvatarUrl}
          alt="Matthias Voss"
          style={styles.beraterAvatar}
        />
        <div>
          <b>Matthias Voss</b>
          <p></p>
          <address style={{ fontSize: "0.8em" }}>
            Landgrafenstraße 15
            <br />
            10787 Berlin
          </address>
        </div>
      </div>
      <div className="row">
        <div className="text-left mt-2">
          <div style={{ fontSize: "0.8em" }}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="tel:+493020913790"
            >
              Tel.: +49 30 2091 37 90
            </a>
            <div>Fax: +49 30 209137 922</div>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="mailto:Matthias.Voss@Feuersozietaet.de"
            >
              E-Mail: Matthias.Voss@Feuersozietaet.de
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BeraterInfo;
