import React from "react";

export default function Loading() {
  return (
    <div className="loading">
      <svg>
        <circle
          r="50"
          cx="150"
          cy="75"
          stroke="#de2920"
          strokeWidth="5px"
          fill="none"
        />
      </svg>
    </div>
  );
}
