// @flow
// @format
import React from 'react';

type ThemeType = 'primary' | 'secondary' | 'default';

const backgroundColor = {
  primary: '#de2920',
  secondary: '#2c3e50',
  default: '#2980b9',
};

const InfoBox = ({
  children,
  theme = 'primary',
  style,
}: {
  children: any,
  theme: ThemeType,
  style: Object,
}) => {
  return (
    <div
      style={{
        padding: 10,
        backgroundColor: 'rgba(200, 200, 200, 0.2)',
        borderTop: `3px solid ${backgroundColor[theme]}`,
        marginBottom: 15,
        ...(style ? style : {}),
      }}>
      {children}
    </div>
  );
};

export default InfoBox;
