import React, {useEffect} from 'react';
import styled from '@emotion/styled';

const ModalBox = styled.div`
  width: ${window.innerWidth < 1000 ? '96vw' : '85vw'};
  height: auto;
  max-height: 90vh;
  overflow-y: auto;
  background: #fff;
  padding: 15px;
  position: absolute;
  box-shadow: 0 0 15px rgba(100, 100, 100, 0.3);
  z-index: ${({open}) => (open ? 99 : -1)};
  top: ${({open}) => (open ? '5vh' : '200vh')};
  left: ${window.innerWidth < 1000 ? '2vw' : 15 / 2 + 'vw'};
  transition: all 500ms ease-in-out;
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  opacity: ${({open}) => (open ? 1 : 0)};
  transition: all 300ms ease;
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${({open}) => (open ? 98 : -1)};
  background-color: rgba(100, 100, 100, 0.3);
`;

const Button = styled.button`
  margin-bottom: 10px;
  float: right;
`;

export default function Modal({open = false, toggle = () => null, children}) {
  useEffect(
    () => {
      function checkKeypress(e) {
        if (e.code === 'Escape') {
          toggle();
        }
      }
      function checkClick(e) {
        if (e.target.id === 'backdrop') toggle();
      }
      if (open) {
        document.addEventListener('keydown', checkKeypress);
        document.addEventListener('click', checkClick);
      } else {
        document.removeEventListener('keydown', checkKeypress);
        document.removeEventListener('click', checkClick);
      }
    },
    [open],
  );

  return (
    <Container open={open} id="backdrop">
      <ModalBox open={open}>
        <Button className="btn btn-secondary" onClick={toggle} type="button">
          Schliessen
        </Button>
        {children}
      </ModalBox>
    </Container>
  );
}
