// @flow
// @format
import React from "react";

import i18n from "./../../constants/i18n";
import { insuranceTypes } from "./../../constants/insuranceTypes";

const verzichtVarianten = {
  "1": "Das Risiko ist nicht vorhanden",
  "2": "Der Kunde möchte das Risiko bzw. den Risikoanteil selbst tragen",
  "3": "Das Risiko ist anderweitig versichert",
  "4": "Das Risiko ist nicht versicherbar",
  "5": "Der Versicherungsschutz ist dem Kunden zu teuer",
  "6": "Abweichenden Grund erfassen"
};

type VerzichtVarianteType = {
  title: string,
  label: string,
  varianten: Array<string>
};

type Props = {
  form: Object,
  setForm: Function,
  props: any
};

export default React.memo(function BeratungsVerzicht({
  setForm,
  form,
  availableVariants,
  ...props
}: Props) {
  return (
    <div>
      {Object.entries(availableVariants).map(
        (legal: [string, VerzichtVarianteType], i) => {
          const varianten = legal[1].varianten;
          return (
            <div className="col-12" key={legal[0]}>
              <div className="custom-inputs">
                <p
                  className="text-muted"
                  style={{ maginBottom: 0, paddingBottom: 0 }}
                >
                  {legal[1].title}
                </p>
                <input
                  className="form-control"
                  name={legal[0]}
                  onChange={e => {
                    setForm({
                      ...form,
                      ...{
                        [legal[0]]: e.target.checked
                      },
                      ...(!e.target.checked
                        ? { [legal[0] + "_grund"]: "" }
                        : {})
                    });
                  }}
                  required
                  type="checkbox"
                  checked={form[legal[0]]}
                />
                <label htmlFor={legal[0]}>
                  <p className="custom-inputs-label-p">{legal[1].label}</p>
                </label>
              </div>
              {form[legal[0]] && varianten.length > 0 && (
                <div className="col-12">
                  {varianten.map((variant, i) => {
                    const name = `${legal[0]}_grund`;
                    const key = `${name}_${variant}`;
                    return (
                      <div key={key} className="form-check">
                        <input
                          required
                          name={name}
                          id={key}
                          type="radio"
                          value={variant}
                          checked={
                            Number(variant) === 6 &&
                            [1, 2, 3, 4, 5].indexOf(
                              Number(form[legal[0] + "_grund"])
                            ) === -1
                              ? true
                              : Number(form[name]) === Number(variant)
                          }
                          className="form-check-input"
                          onChange={e =>
                            setForm({ ...form, ...{ [name]: e.target.value } })
                          }
                        />
                        <label
                          htmlFor={key}
                          className="form-check-label"
                          style={{ fontWeight: "400" }}
                        >
                          {verzichtVarianten[variant]}
                        </label>
                      </div>
                    );
                  })}
                  {([1, 2, 3, 4, 5].indexOf(
                    Number(form[legal[0] + "_grund"])
                  ) === -1 ||
                    !form[legal[0] + "_grund"]) && (
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Abweichender Grund..."
                      value={
                        form[legal[0] + "_grund"] !== "6"
                          ? form[legal[0] + "_grund"]
                          : ""
                      }
                      onChange={e =>
                        setForm({
                          ...form,
                          ...{ [legal[0] + "_grund"]: e.target.value }
                        })
                      }
                    />
                  )}
                  <hr />
                </div>
              )}
            </div>
          );
        }
      )}
    </div>
  );
});
