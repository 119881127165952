const STEPS = [
  {
    id: 1,
    title: 'Start',
  },
  {
    id: 2,
    title: 'Laubenversicherung',
  },
  {
    id: 3,
    title: 'Optionen',
  },
  {
    id: 4,
    title: 'Persönliche Daten',
  },
  {
    id: 5,
    title: 'Übersicht & Abschluss',
  },
];

export default STEPS;
