// @flow
import React, { useEffect, useRef } from "react";

import FormActions from "./../FormActions";
import TotalCosts from "./../TotalCosts";
import Elementarschaeden from "./../Elementarschaeden";

import useFormState from "./../../hooks/useFormState";
import useTotalCosts from "./../../hooks/useTotalCosts";

import {
  OPTION_NAMES,
  getOptionsByInsurance
} from "./../../constants/insuranceTypes";
import type { FormType } from "./../../FlowTypes";

const initialState: FormType = {
  optionen: {
    plus_gewaechshaus: false,
    plus_gewaechshaus_groesse: "0",
    plus_garage: false,
    plus_garage_anzahl: "0",

    // NUR KOMBI
    plus_erhoehung: false,
    plus_erhoehung_vsumme_cents: "0",
    plus_glaskeramik: false,
    plus_solaranlage: false,
    plus_solaranlage_vsumme_cents: "0",

    // AUSSERHALB B/BB -> Komfort, Basis, Einzel
    plus_reetdach: false,
    plus_reetdach_groesse: "0",

    // INNERHALB B/BB> Plus, Einzel IN
    plus_aufraeumungskosten: false,

    // IN B/BB -> Einzel IN
    plus_hausrat_einzelversicherung: false,
    plus_hausrat_einzelversicherung_vsumme_cents: "0",
    plus_glas_einzelversicherung: false,
    // plus_leitungswasser: false,

    // IN BBB && KOMBI 2000 PLUS
    plus_elementar: false,

    plus_elementar_plz: "",
    plus_elementar_ort: "",
    plus_elementar_strasse: "",
    plus_elementar_hausnummer: "",
    plus_elementar_angrenzende_strasse1: "",
    plus_elementar_angrenzende_strasse2: "",
    plus_elementar_angrenzende_strasse3: "",
    plus_elementar_mit_vorschaden: false,
    plus_elementar_anzahl_vorschaeden: 0,
    plus_elementar_keller: false,
    plus_elementar_rueckstausicherung: false
  }
};

export const connectedValues = {
  plus_gewaechshaus: "plus_gewaechshaus_groesse",
  plus_garage: "plus_garage_anzahl",
  plus_erhoehung: "plus_erhoehung_vsumme_cents",
  plus_solaranlage: "plus_solaranlage_vsumme_cents",
  plus_hausrat_einzelversicherung:
    "plus_hausrat_einzelversicherung_vsumme_cents",
  plus_reetdach: "plus_reetdach_groesse",
  plus_elementar_keller: "plus_elementar_rueckstausicherung"
};

const SECOND_INPUT_INFO = {
  [connectedValues.plus_gewaechshaus]:
    "gegen Feuer-, Sturm- und Hagelschäden (ohne Inhalt)",
  [connectedValues.plus_garage]:
    "gegen Feuer-, Sturm- und Hagelschäden (ohne Inhalt)",
  [connectedValues.plus_erhoehung]:
    "mind. 500 € Versicherungssumme, max. 10.000€, in 500er Schritten",
  [connectedValues.plus_solaranlage]:
    "gegen einfachen Diebstahl, mind. 1000 € Versicherungssumme, in 100er Schritten <br/><p><b>Zur Information:</b> Gegen Feuer-, Sturm- und Hagelschäden besteht Versicherungsschutz, wenn die Solaranlage auf dem Dach der versicherten Gebäude montiert ist.</p>"
};

function ThirdStep({
  currentStep,
  prevStep
}: {
  currentStep: number,
  prevStep: Function
}) {
  const {
    form,
    setForm,
    clear,
    savedForm,
    getByKey
  }: {
    form: FormType,
    setForm: Function,
    clear: Function,
    savedForm: Function,
    getByKey: string => mixed
  } = useFormState(initialState);
  const ref = useRef();

  const {
    summeGebaeude,
    summeHausrat,
    summeOptionen,
    summeGesamt,
    recalc
  } = useTotalCosts(0);

  if (getByKey("colonyId") == null || savedForm() == null) {
    window.location = window.location.pathname;
    return null;
  }

  useEffect(() => {
    // This prevents unnecessary API Calls to calculate new values,
    // if only the Street names change
    // expl: If we have no ref.current (value before state change) -> recalc
    // if we have an old state value AND only streetnames changeed -> NO recalc
    // if anything else changed -> recalc
    if (ref.current) {
      if (
        form.optionen.plus_elementar_plz !==
          ref.current.optionen.plus_elementar_plz ||
        form.optionen.plus_elementar_ort !==
          ref.current.optionen.plus_elementar_ort ||
        form.optionen.plus_elementar_strasse !==
          ref.current.optionen.plus_elementar_strasse ||
        form.optionen.plus_elementar_hausnummer !==
          ref.current.optionen.plus_elementar_hausnummer ||
        form.optionen.plus_elementar_angrenzende_strasse1 !==
          ref.current.optionen.plus_elementar_angrenzende_strasse1 ||
        form.optionen.plus_elementar_angrenzende_strasse2 !==
          ref.current.optionen.plus_elementar_angrenzende_strasse2 ||
        form.optionen.plus_elementar_angrenzende_strasse3 !==
          ref.current.optionen.plus_elementar_angrenzende_strasse3
      ) {
      } else {
        recalc();
      }
    } else {
      recalc();
    }
  }, [form.optionen]);

  useEffect(() => {
    ref.current = form;
  });

  function getResetOption(optionKey, value) {
    if (value) {
      return {};
    }

    if (connectedValues[optionKey]) {
      return {
        [connectedValues[optionKey]]:
          initialState.optionen[connectedValues[optionKey]]
      };
    }

    if (optionKey === "plus_elementar") {
      return {
        plus_elementar_plz: "",
        plus_elementar_ort: "",
        plus_elementar_strasse: "",
        plus_elementar_hausnummer: "",
        plus_elementar_angrenzende_strasse1: "",
        plus_elementar_angrenzende_strasse2: "",
        plus_elementar_angrenzende_strasse3: ""
      };
    }
  }

  function handleInputChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setForm({
      ...form,
      optionen: {
        ...form.optionen,
        ...getResetOption(name, value),
        [name]: value
      }
    });
  }

  function toggleVorschaden() {
    setForm({
      ...form,
      optionen: {
        ...form.optionen,
        ...{
          plus_elementar_mit_vorschaden: !form.optionen
            .plus_elementar_mit_vorschaden,
          plus_elementar_anzahl_vorschaeden:
            !form.optionen.plus_elementar_mit_vorschaden === true ? 1 : 0
        }
      }
    });
  }

  function resetElementar() {
    setForm({
      ...form,
      optionen: {
        ...form.optionen,
        plus_elementar_plz: "",
        plus_elementar_ort: "",
        plus_elementar_strasse: "",
        plus_elementar_hausnummer: ""
      }
    });
  }

  // special cases for connected fields
  function checkForSecondField(optionKey) {
    if (optionKey === "plus_gewaechshaus_groesse") {
      return form.optionen.plus_gewaechshaus;
    }
    if (optionKey === "plus_garage_anzahl") {
      return form.optionen.plus_garage;
    }
    if (optionKey === "plus_erhoehung_vsumme_cents") {
      return form.optionen.plus_erhoehung;
    }
    if (optionKey === "plus_solaranlage_vsumme_cents") {
      return form.optionen.plus_solaranlage;
    }
    if (optionKey === "plus_hausrat_einzelversicherung_vsumme_cents") {
      return form.optionen.plus_hausrat_einzelversicherung;
    }
    if (optionKey === "plus_reetdach_groesse") {
      return form.optionen.plus_reetdach;
    }
    return true;
  }

  const availableInsuranceOptions = getOptionsByInsurance(
    getByKey("versicherung")
  );

  // SUMME ALLER OPTIONEN KOSTEN
  let summeOptionenGesamt = 0;
  Object.entries(summeOptionen).map(s => {
    summeOptionenGesamt += s[1];
    return true;
  });

  function getMinVal(key) {
    switch (key) {
      case "plus_solaranlage_vsumme_cents":
        return "1000";
      case "plus_hausrat_einzelversicherung_vsumme_cents":
        return "3000";
      case "plus_erhoehung_vsumme_cents":
        return "500";
      default:
        return "1";
    }
  }

  function getMaxVal(key) {
    switch (key) {
      case "plus_gewaechshaus_groesse":
        return { max: 50 };
      case "plus_garage_anzahl":
        return { max: 3 };
      case "plus_erhoehung_vsumme_cents":
        return { max: 10000 };

      default:
        return {};
    }
  }

  function getSteps(key) {
    switch (key) {
      case "plus_solaranlage_vsumme_cents":
        return "100";
      case "plus_erhoehung_vsumme_cents":
        return "500";
      default:
        return "1";
    }
  }

  const infoTextStyles = {
    paddingLeft: 45,
    position: "relative",
    lineHeight: 1,
    marginTop: -10
  };
  function getInfoText(opKey) {
    if (opKey === "plus_reetdach") {
      return (
        <div style={infoTextStyles}>
          <small>
            Bitte beachten, wenn ein Reetdach vorhanden ist, muss dieses auch
            versichert werden.
          </small>
        </div>
      );
    }
    return null;
  }

  return (
    <div className="row">
      <div className="col-12 col-md-12 mb-4">
        <div className="row">
          <div className="col-6 text-left">
            <h6>
              Beitrag inkl. Versicherungssteuer
            </h6>
          </div>
          <div className="col-6 text-right">
            <h5 className="text-muted">
              <TotalCosts numberOnly value={summeGesamt - summeOptionenGesamt} />€
            </h5>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-12">
        <h4 className="mb-4">Zusatzversicherungen:</h4>
        <div className="form-group">
          <div className="row">
            {form.optionen &&
              availableInsuranceOptions.map(opKey => {
                const option = form.optionen[opKey];
                if (typeof option === "boolean") {
                  return (
                    <div key={opKey} className="col-12">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-9">
                            <div className="custom-inputs">
                              <input
                                name={opKey}
                                type="checkbox"
                                className="form-control"
                                aria-describedby={opKey}
                                checked={form.optionen[opKey]}
                                onChange={handleInputChange}
                              />
                              <label htmlFor={opKey}>
                                <p className="custom-inputs-label-p">
                                  + {OPTION_NAMES[opKey]}
                                </p>
                              </label>
                            </div>
                            {getInfoText(opKey)}
                          </div>
                          <div className="col-3 text-right">
                            <TotalCosts
                              value={summeOptionen[opKey]}
                              textStyle={{ fontSize: "1.2rem" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                if (checkForSecondField(opKey)) {
                  return (
                    <div key={opKey} className="col-12">
                      <div className="form-group">
                        <label htmlFor={opKey}>{OPTION_NAMES[opKey]}</label>
                        <div className="col-5">
                          <input
                            name={opKey}
                            required
                            type="number"
                            min={getMinVal(opKey)}
                            {...getMaxVal(opKey)}
                            step={getSteps(opKey)}
                            className="form-control"
                            aria-describedby={opKey}
                            value={form.optionen[opKey]}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-10">
                          <small
                            dangerouslySetInnerHTML={{
                              __html: SECOND_INPUT_INFO[opKey]
                            }}
                          />
                        </div>
                        <hr />
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            {form.versicherung === "Kombi-2000-Plus" &&
              form.district.in_bbb && (
                <Elementarschaeden
                  setForm={setForm}
                  form={form}
                  summeOptionen={summeOptionen}
                  handleInputChange={handleInputChange}
                  resetElementar={resetElementar}
                  toggleVorschaden={toggleVorschaden}
                />
              )}
          </div>
        </div>
        <div className="col-12 pr-0 mb-4">
          <hr />
          <div className="text-right">
            <TotalCosts value={summeOptionenGesamt} title="Gesamt (Optionen)" />
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-12">
        <div className="row">
          <div className="col-12 col-lg-6 text-center-small">
            <TotalCosts
              value={summeGebaeude}
              title="Versicherungssumme Gebäude"
            />
          </div>
          <div className="col-12 col-lg-6 text-center-small">
            <TotalCosts
              value={summeHausrat}
              title="Versicherungssumme Hausrat"
            />
          </div>
          <div className="col-12">
            <hr />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 offset-0 offset-lg-6 col-lg-6 text-center-small">
            <TotalCosts
              value={summeGesamt}
              title="Gesamtsumme Beitrag inkl. Zusatzversicherungen"
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        <FormActions
          clear={clear}
          currentStep={currentStep}
          prevStep={prevStep}
          enableForwardButton={true}
        />
      </div>
    </div>
  );
}

export default ThirdStep;
