import React from 'react';
import Numeral from 'numeral';
import {CSSTransitionGroup} from 'react-transition-group'; // ES6

Numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 't',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  currency: {
    symbol: '€',
  },
});

const totalCosts = React.memo(function totalCosts({
  value,
  title,
  numberOnly = false,
  textStyle = {},
}) {
  Numeral.locale('de');
  const betragInEuro = value / 100;

  if (numberOnly) {
    return Numeral(betragInEuro).format('0,00.00', Math.floor);
  }

  return (
    <>
      <h6>{title}</h6>
      <CSSTransitionGroup
        transitionName="numberValue"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionLeave={false}
        transitionEnterTimeout={500}>
        <h4 className="text-muted" style={textStyle}>
          {Numeral(betragInEuro).format('0,00.00', Math.floor)}€
        </h4>
      </CSSTransitionGroup>
    </>
  );
});

export default totalCosts;
