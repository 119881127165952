import {useState, useEffect} from 'react';

const STORAGE_KEY = 'laub_form_data';

function useFormState(initialState) {
  const [form, setForm] = useState(initialState);

  useEffect(
    () => {
      // check if browser supports localStorage
      if (window.localStorage && form !== null) {
        const oldFormDataString = window.localStorage.getItem(STORAGE_KEY);
        let newFormData = {};
        // check if there is old data that we can use for the form state
        if (oldFormDataString) {
          const oldFormData = JSON.parse(oldFormDataString);

          // when old data is available, set it as initial state
          // only when there is no state already (e.g. componentDidMount)
          if (form === initialState) {
            setForm({
              ...initialState,
              ...{
                ...oldFormData,
                optionen: {
                  ...initialState.optionen,
                  ...oldFormData.optionen,
                },
              },
            });
          }

          // create newFormData for localStorage with the stored data
          // and the new inputs from update
          newFormData = {
            ...oldFormData,
            ...form,
          };
        } else {
          newFormData = form;
        }
        if (newFormData.optionen == null) {
          delete newFormData.optionen;
        }

        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(newFormData));
      }
    },
    [form],
  );

  function clearStorage() {
    setForm(initialState);
    window.localStorage.removeItem(STORAGE_KEY);
  }

  function clear() {
    if (window.confirm('Wollen sie Ihre Eingaben wirklich zurücksetzten?')) {
      setForm(initialState);

      if (window.localStorage) {
        clearStorage();
        window.location = '/';
      }
    }
  }

  function getByKey(key) {
    const savedString = window.localStorage.getItem(STORAGE_KEY);
    if (savedString) {
      const parsedString = JSON.parse(savedString);
      return parsedString[key];
    } else {
      return null;
    }
  }

  function savedForm() {
    const savedString = window.localStorage.getItem(STORAGE_KEY);
    if (savedString !== null) {
      return JSON.parse(savedString);
    } else {
      return null;
    }
  }

  return {form, setForm, clear, getByKey, savedForm, clearStorage};
}

export default useFormState;
