import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

function FormActions({
  currentStep,
  prevStep,
  clear,
  enableForwardButton,
  finishAction,
  showFinishButton
}) {
  return (
    <>
      <hr />
      <div className="row flex-column-reverse flex-md-row mb-3">
        <div className="col-12 col-md-3 mb-2">
          <button
            className="btn btn-light btn-block"
            type="button"
            onClick={clear}
          >
            Zurücksetzen
          </button>
        </div>
        <div className="col-12 col-md-3 mb-2">
          {currentStep > 1 && (
            <button
              className="btn btn-secondary btn-block"
              type="button"
              onClick={prevStep}
            >
              <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: 15 }} />
              Zurück
            </button>
          )}
        </div>
        <div className="col-12 col-md-6 pull-right mb-2">
          {!showFinishButton ? (
            <button
              disabled={!enableForwardButton}
              className="btn btn-primary btn-block pull-right"
              type="submit"
            >
              Weiter
              <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: 15 }} />
            </button>
          ) : (
            <button
              onClick={finishAction}
              disabled={!enableForwardButton}
              className="btn btn-success btn-block pull-right"
            >
              Jetzt kostenpflichtig abschließen
              <FontAwesomeIcon icon={faPaperPlane} style={{ marginLeft: 15 }} />
            </button>
          )}
        </div>
      </div>
    </>
  );
}

FormActions.propTypes = {
  currentStep: PropTypes.number,
  prevStep: PropTypes.func,
  clear: PropTypes.func,
  finishAction: PropTypes.func,
  enableForwardButton: PropTypes.bool,
  showFinishButton: PropTypes.bool
};

export default FormActions;
