import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

const styles = {
  wrapper: {
    padding: 5,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#de2920",
    alignSelf: "flex-start",
    borderRadius: 40,
    width: 27,
    height: 27,
    cursor: "pointer",
    position: "relative"
  },
  messageWrapper: {
    zIndex: 99,
    position: "absolute",
    marginLeft: window.innerWidth < 510 ? -200 : 0,
    marginBottom: 190,
    width: window.innerWidth < 510 ? '90vw' : 400,
    transition: "all 250ms ease-in-out",
    padding: 15,
    fontSize: "0.8em",
    background: '#fff',
    boxShadow: "rgba(100, 100, 100, 0.5) 0px 2px 7px",
    borderRadius: 5,
  }
};

function InfoButton({ message }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleClick = () => {
      setShow(false);
    };
    if (show) {
      document.addEventListener("click", handleClick);
    } 

    return () => document.removeEventListener("click", handleClick);
  }, [show]);

  return (
    <div style={styles.wrapper} role="button" onClick={() => setShow(!show)}>
      <FontAwesomeIcon
        icon={faInfo}
        style={{
          fontSize: "0.8em",
          color: "#fff",
          textAlign: "center",
        }}
      />
      {show ? (
        <div style={styles.messageWrapper}>
          <p>{message}</p>
        </div>
      ) : null}
    </div>
  );
}

export default InfoButton;
