// @flow
// @format
export default {
  beratungsLink: "https://www.feuersozietaet.de/content/aussendienst/m/matthias_voss/",
  contact: {
    phone: "030 209 13 790",
    phone_int: "+493020913790",
  },
  legal: {
    einverstaendnis_nicht_in_papierform: {
      title:
        "Bitte erklären Sie sich damit einverstanden, dass Ihnen die Vermittlerinformation nicht in Papierform zugesendet wird.",
      label:
        "Ich bin einverstanden, dass mir die Vermittlerinformation nicht in Papierform übersendet wird.",
    },
    einverstaendnis_auskunft_ueber_website: {
      title:
        "Bitte erklären Sie Ihre Zustimmung zur Auskunfserteilung über den/die Vermittler über diese Webseite.",
      label:
        "Ich erkläre meine Zustimmung zur Auskunfserteilung über den/die Vermittler über diese Webseite.",
    },
    // einverstaendnis_vermittlerinfo_gelesen: {
    //   title:
    //     "Bitte bestätigen Sie, dass Sie die Vermittlerinformation gelesen haben.",
    //   label: "Ich habe die Vermittlerinformation gelesen."
    // },
    // einverstaendnis_persoenliche_beratung: {
    //   title: "",
    //   label: "Beratung und Empfehlung ",
    //   desc: "",
    // },
  },
  legalFirst: {
    einverstaendnis_beratungsverzicht: {
      title: "",
      label: "Ich verzichte auf eine Beratung und möchte online abschließen",
      desc:
        "Ich verzichte auf Beratung. Mir ist bewusst, dass sich mein Verzicht nachteilig auf die Möglichkeit auswirken kann, gegen den Versicherungsvermittler oder die Versicherung einen Schadensersatzanspruch wegen Verletzung der Beratungs- und Dokumentationspflicht geltend zu machen.",
    },
  },
  beratungsVerzicht: {
    ausserhalbBBB: {
      beratung_absicherung_laubeninhalt: {
        title: "",
        connectedValue: "plus_erhoehung",
        label:
          "Abweichend von der Empfehlung, keine Absicherung des Laubeninhalts.",
        varianten: [1, 2, 3, 5, 6],
      },
      beratung_leitungswasser: {
        title: "",
        connectedValue: "plus_leitungswasser",
        label:
          "Abweichend von der Empfehlung, keine Absicherung gegen Leitungswasserschäden.",
        varianten: [1, 2, 3, 5, 6],
      },
      beratung_feuer_sturm_gewaechshaus: {
        title: "",
        connectedValue: "plus_gewaechshaus",
        label:
          "Abweichend von der Empfehlung, keine Absicherung für Feuer- und Sturmschäden an Gewächshäusern.",
        varianten: [1, 2, 3, 5, 4, 6],
      },
      beratung_feuer_sturm_garage: {
        title: "",
        connectedValue: "plus_garage",
        label:
          "Abweichend von der Empfehlung, keine Absicherung für Feuer- und Sturmschäden an Garagen.",
        varianten: [1, 2, 3, 5, 4, 6],
      },
      beratung_diebstahl_vandalismus_solar: {
        title: "",
        connectedValue: "plus_solaranlage",
        label:
          "Abweichend von der Empfehlung, keine Absicherung für einfachen Diebstahl / Vandalismus an Solaranlagen.",
        varianten: [1, 2, 3, 4, 5, 6],
      },
      beratung_aufraeumkosten_sturm_blitz: {
        title: "",
        connectedValue: "plus_aufraeumungskosten",
        label:
          "Abweichend von der Empfehlung, keine bsicherung für Aufräumungskosten der durch Sturm oder Blitz umgestürzten Bäume.",
        varianten: [1, 2, 3, 5, 4, 6],
      },
      beratung_absicherung_laubeninhalt_sowie_glasbruchschaeden: {
        title: "",
        connectedValue: "plus_glas_einzelversicherung",
        label:
          "Abweichend von der Empfehlung, keine Absicherung für Glasbruchschäden.",
        varianten: [1, 2, 3, 4, 5, 6],
      },
      beratung_glasbruchschaeden_glaskeramik: {
        title: "",
        connectedValue: "plus_glaskeramik",
        label:
          "Abweichend von der Empfehlung, keine Absicherung für Glasbruchschäden am Glaskeramikkochfeld.",
        varianten: [1, 2, 3, 4, 5, 6],
      },
    },
    inBBB: {
      beratung_absicherung_laubeninhalt: {
        title: "",
        label:
          "Abweichend von der Empfehlung, keine Absicherung des Laubeninhalts.",
        varianten: [1, 2, 3, 5, 6],
      },
      beratung_laube: {
        title: "",
        label:
          "Abweichend von der Empfehlung, keine Absicherung für LW-Schäden.",
        varianten: [2, 3, 5, 6],
      },
      beratung_feuer_sturm_gewaechshaus: {
        title: "",
        connectedValue: "plus_gewaechshaus",
        label:
          "Abweichend von der Empfehlung, keine Absicherung für Feuer- und Sturmschäden an Gewächshäusern.",
        varianten: [1, 2, 3, 5, 4, 6],
      },
      beratung_feuer_sturm_garage: {
        title: "",
        connectedValue: "plus_garage",
        label:
          "Abweichend von der Empfehlung, keine Absicherung für Feuer- und Sturmschäden an Garagen.",
        varianten: [1, 2, 3, 5, 4, 6],
      },
      beratung_diebstahl_vandalismus_solar: {
        title: "",
        connectedValue: "plus_solaranlage",
        label:
          "Abweichend von der Empfehlung, keine Absicherung für einfachen Diebstahl / Vandalismus an Solaranlagen.",
        varianten: [1, 2, 3, 4, 5, 6],
      },
      beratung_weitere_elementargefahren: {
        title: "",
        connectedValue: "plus_elementar",
        label:
          "Abweichend von der Empfehlung, keine Absicherung für weitere Elementargefahren.",
        varianten: [2, 3, 5, 4, 6],
      },
      beratung_aufraeumkosten_sturm_blitz: {
        title: "",
        connectedValue: "plus_aufraeumungskosten",
        label:
          "Abweichend von der Empfehlung, keine Absicherung für Aufräumungskosten der durch Sturm oder Blitz umgestürzten Bäume.",
        varianten: [1, 2, 3, 4, 5, 6],
      },
      // beratung_leitungswasser_sturm_einbruchdiebstahl: {
      //   title: "",
      //   connectedValue: "plus_leitungswasser",
      //   label:
      //     "Abweichend von der Empfehlung, keine Absicherung des Laubeninhalts für Schäden durch Leitungswasser, Sturm / Hagel  sowie  Einbruchdiebstahl.",
      //   varianten: [1, 2, 3, 5, 6]
      // },

      beratung_glasbruchschaeden: {
        title: "",
        connectedValue: "plus_glas_einzelversicherung",
        label:
          "Abweichend von der Empfehlung, keine Absicherung für Glasbruchschäden.",
        varianten: [1, 2, 3, 5, 6],
      },

      beratung_glasbruchschaeden_glaskeramik: {
        title: "",
        connectedValue: "plus_glaskeramik",
        label:
          "Abweichend von der Empfehlung, keine Absicherung für Glasbruchschäden am Glaskeramikkochfeld.",
        varianten: [1, 2, 3, 4, 5, 6],
      },
    },
    beratungZusatz: {
      anmkerkung_beratung: {
        title: "Anmkerung zur Beratung",
        type: "text",
      },
      beratung_weitere_versicherungen: {
        title: "Beratung zu folgenden weiteren Versicherungen",
        type: "text",
      },
    },
  },
};
