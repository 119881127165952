import React from 'react';

import STEPS from './../../constants/formSteps';

import './StepsHeader2.scss';

function StepsHeader({currentStep, setCurrentStep}) {
  const currentStepItem = STEPS.filter(step => step.id === currentStep)[0];
  return (
    <div>
      <div className="row">
        <ul className="list-unstyled multi-steps">
          {STEPS.map((step, stepIndex) => {
            const isPrevStep = stepIndex < currentStep;
            const isCurrent = currentStep === step.id;
            return (
              <li
                key={step.id}
                className={
                  isCurrent ? 'is-active' : isPrevStep ? 'c-pointer' : ''
                }
                onClick={
                  !isCurrent && isPrevStep
                    ? () => setCurrentStep(stepIndex + 1)
                    : null
                }>
                <span className="step-title">{step.title}</span>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="header-step-title">
        <div className="row">
          <div className="col-12 col-md-6 text-center">
            <h4>{currentStepItem ? currentStepItem.title : null}</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepsHeader;
