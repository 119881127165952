import { useState } from "react";

import useFormState from "./useFormState";

import ApiService from "./../services/apiService";

function useTotalCosts() {
  const [summeGesamt, setsummeGesamt] = useState(0);
  const [summeGebaeude, setSummeGebaeude] = useState(0);
  const [summeHausrat, setSummeHausrat] = useState(0);
  const [summeOptionen, setSummeOptionen] = useState({});
  const { savedForm, getByKey } = useFormState(null);

  function recalc() {
    let optionParmas = {};
    const currentForm = savedForm();
    if (currentForm.optionen) {
      Object.entries(currentForm.optionen).map(option => {
        optionParmas[option[0]] = option[1];
        return true;
      });
    }

    ApiService.getSummeGebaeude({
      versicherung: getByKey("versicherung"),
      groesse: getByKey("groesse"),
      ...optionParmas
    }).then(resp => {
      setSummeGebaeude(resp.vsumme_gebaeude);
    });
    if (optionParmas["plus_hausrat_einzelversicherung_vsumme_cents"] > 0) {
      setSummeHausrat(
        optionParmas["plus_hausrat_einzelversicherung_vsumme_cents"]
      );
    } else {
      ApiService.getSummeHausrat({
        versicherung: getByKey("versicherung"),
        groesse: getByKey("groesse"),
        ...optionParmas
      }).then(resp => {
        setSummeHausrat(resp.vsumme_hausrat);
      });
    }
    ApiService.getGesamtBetrag({
      versicherung: getByKey("versicherung"),
      groesse: getByKey("groesse"),
      ...optionParmas
    }).then(resp => {
      setsummeGesamt(resp.gesamt_jbetrag);
    });
    ApiService.getOptionsBetrag({
      versicherung: getByKey("versicherung"),
      groesse: getByKey("groesse"),
      ...optionParmas
    }).then(resp => {
      setSummeOptionen(resp);
    });
  }

  return {
    summeGebaeude,
    summeHausrat,
    summeGesamt,
    summeOptionen,
    recalc
  };
}

export default useTotalCosts;
