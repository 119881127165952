// @flow
import React, { useState } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import InfoBox from "./../InfoBox";

import Modal from "./../Modal";

const AccordionHeader = styled.div`
  padding: 5px;
  font-weight: bold;
  z-index: 2;
  position: relative;
  font-size: 0.8rem;
  background-color: rgba(100, 100, 100, 0.1);
  color: #000;
`;

const AccordionContent = styled.div`
  padding: 10px;
  overflow: hidden;
  font-size: 0.8rem;
  color: #000;
  max-height: ${props => (props.open ? "2000px" : "0px")};
  opacity: ${props => (props.open ? "1" : "0")};
  transform-origin: 0 0;
  transition: all 650ms ease;
`;

const OpenText = styled.span`
  font-weight: normal;
  font-size: 0.7rem;
  float: right;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const defaultThead = (
  <thead>
    <tr>
      <th style={{ width: "25%" }} />
      <th style={{ width: "15%" }} />
      <th style={{ width: "10%" }} />
      <th colSpan="2" style={{ width: "30%" }}>
        <h6>
          <b>Basis</b>
        </h6>
      </th>
      <th style={{ width: "10%" }} />
      <th colSpan="2" style={{ width: "20%" }}>
        <h6>
          <b>Kombi 2000 Komfort</b>
        </h6>
      </th>
    </tr>
    <tr>
      <th style={{ width: "25%" }}>
        <h6>Hausrat</h6>
      </th>
      <th style={{ width: "15%" }} />
      <th style={{ width: "10%" }}>Versicherungssumme</th>
      <th style={{ width: "15%" }}>
        <div>Beispiel 24qm Laube</div>
        <div>max. Entschädigung</div>
      </th>
      <th style={{ width: "10%" }} />
      <th style={{ width: "10%" }}>Versicherungssumme</th>
      <th style={{ width: "15%" }}>
        <div>Beispiel 24qm Laube</div>
        <div>max. Entschädigung</div>
      </th>
    </tr>
  </thead>
);

export default function VersicherungenInfo({ inBBB }: { inBBB: boolean }) {
  const [open, setOpen] = useState(false);
  const [showTable, setShowTable] = useState(false);

  return (
    <div>
      <AccordionHeader onClick={() => setOpen(!open)}>
        * Informationen zu Versicherungsarten
        <OpenText>{open ? "Ausblenden" : "Einblenden"}</OpenText>
      </AccordionHeader>
      <AccordionContent open={open}>
        <h4 style={{ marginTop: 20, marginBottom: 20 }}>
          A: Kombi-2000-
          {inBBB ? "Plus" : "Komfort"}
          -Versicherung – Unsere Empfehlung
        </h4>
        {inBBB && (
          <>
            <div
              style={{
                padding: 10,
                backgroundColor: "rgba(200, 200, 200, 0.2)",
                borderTop: "3px solid #de2920",
                marginBottom: 15,
                marginLeft: 25
              }}
            >
              <ul>
                <li>Gebäudeversicherung zum gleitenden Neuwert</li>
                <li>Hausratversicherung</li>
                <li>Glasversicherung</li>
              </ul>
            </div>
            <div style={{ marginLeft: 25 }}>
              <div
                style={{
                  padding: 10,
                  backgroundColor: "rgba(200, 200, 200, 0.2)",
                  borderTop: "3px solid #de2920",
                  marginBottom: 15
                }}
              >
                <h5>Gebäudeversicherung zum gleitenden Neuwert</h5>
                <ul>
                  <li>
                    ist eine Versicherung zum gleitenden Neuwert. Wir
                    garantieren im Schadensfall den Wiederaufbauwert der Laube /
                    des Wochenendhauses sowohl bei Stein- als auch bei
                    Holzgebäuden, wenn die bebaute Wohn-/ Nutzfläche richtig
                    angegeben wurde.
                  </li>
                  {inBBB && (
                    <li>
                      gegen Schäden durch Feuer, Sturm/ Hagel und Leitungswasser
                    </li>
                  )}
                </ul>
              </div>
              <div
                style={{
                  padding: 10,
                  backgroundColor: "rgba(200, 200, 200, 0.2)",
                  borderTop: "3px solid #de2920",
                  marginBottom: 15
                }}
              >
                <h5>Hausratversicherung</h5>
                <ul>
                  <li>
                    ist eine Neuwertversicherung mit Unterversicherungsverzicht
                    ohne Sicherungsvorschriften. Bei Gebäudebeschädigungen nach
                    einem Einbruch gibt es keine Kostenbegrenzung.
                  </li>
                  <li>
                    gegen Schäden durch Feuer, Sturm/ Hagel, Leitungswasser,
                    Einbruchdiebstahl, Raub und Vandalismus nach dem Einbruch.
                  </li>
                </ul>
              </div>
            </div>
            <InfoBox style={{ marginLeft: 25 }}>
              <h5>Glasversicherung</h5>
              <p>
                Versichert sind sowohl Einfach- als auch Isolierverglasungen.{" "}
              </p>
              <ul />
            </InfoBox>
          </>
        )}

        {!inBBB && (
          <div style={{ marginLeft: 25 }} className="mt-4">
            <div
              style={{
                padding: 10,
                backgroundColor: "rgba(200, 200, 200, 0.2)",
                borderTop: "3px solid #de2920",
                marginBottom: 15
              }}
            >
              <h6>
                Gebäudeversicherung zum gleitenden Neuwert für Schäden durch
                Feuer, Sturm/Hagel, Leitungswasser.
              </h6>
              <p>
                Wir garantieren im Schadenfall den Wiederaufbauwert der Laube /
                des Wochenendhauses sowohl bei Stein- als auch bei Holzgebäuden,
                wenn die bebaute Wohn- / Nutzfläche richtig angegeben wurde.
              </p>
            </div>
            <div
              style={{
                padding: 10,
                backgroundColor: "rgba(200, 200, 200, 0.2)",
                borderTop: "3px solid #de2920",
                marginBottom: 15
              }}
            >
              <h6>
                Hausratversicherung zum Neuwert für Schäden durch Feuer,
                Sturm/Hagel, Leitungswasser, Einbruchdiebstahl, Vandalismus nach
                Einbruch, Raub
              </h6>
              <p>
                Die Hausratversicherung ist eine Neuwertversicherung mit
                Unterversicherungsverzicht, ohne Sicherungsvorschriften. So gibt
                es z.B. bei Gebäudebeschädigungen nach einem Einbruch keine
                Kostenbegrenzung. Der einfache Diebstahl von Gartenmöbeln ist
                mitversichert.
              </p>
            </div>
            <div
              style={{
                padding: 10,
                backgroundColor: "rgba(200, 200, 200, 0.2)",
                borderTop: "3px solid #de2920",
                marginBottom: 15
              }}
            >
              <h6>Galsversicherung inklusive Isolierverglasung</h6>
              <p>Gebäude- und Mobiliarverglasung</p>
            </div>
          </div>
        )}

        <hr style={{ borderTopWidth: 4 }} className="mt-2 mb-2" />

        {!inBBB && (
          <>
            <h4 style={{ marginTop: 20, marginBottom: 20 }}>
              B: Kombi-2000-Basis-Versicherung
            </h4>
            <div style={{ marginLeft: 25 }}>
              <div
                style={{
                  padding: 10,
                  backgroundColor: "rgba(200, 200, 200, 0.2)",
                  borderTop: "3px solid #de2920",
                  marginBottom: 15
                }}
              >
                <p>
                  Schäden durch <b>Leitungswasser</b> sind{" "}
                  <b>
                    weder in der Gebäude- noch in der Hausratversicherung
                    versichert
                  </b>
                  . Es gelten <b>Entschädigungsgrenzen</b>.
                </p>
              </div>

              <div
                style={{
                  padding: 10,
                  backgroundColor: "rgba(200, 200, 200, 0.2)",
                  borderTop: "3px solid #de2920",
                  marginBottom: 15
                }}
              >
                <ul>
                  <li>
                    <b>Gebäudeversicherung zum Neuwert gegen</b> Schäden durch
                    Feuer, Sturm-/ Hagel, ohne Leitungswasser,
                  </li>
                  <li>
                    <b>
                      Hausratversicherung zum Wiederbeschaffungspreis, ohne
                      Leitungswasser gegen Schäden druch Feuer, Sturm / Hagel,
                      Einbruchdiebstahl, Raub und Vandalismus nach einem
                      Einbruch.
                    </b>
                  </li>
                  <li>
                    <b>Glasversicherung</b> Gebäude- und Mobiliarverglasungen
                    (auch Isolierverglasung)
                  </li>
                </ul>
              </div>
            </div>

            <h6>Entschädigungsgrenzen:</h6>
            <br />
            <h6>Gebäude</h6>
            <table className="table table-striped">
              <tbody>
                <tr>
                  <td>
                    <b>Überdachungen / Vordächer</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    max. 5% der Versicherungssumme
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Sturmschäden</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    max. 15% der Versicherungssumme
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      nur bei Feuerschäden: bei Zäunen / Einfriedungen und
                      Kulturen
                    </b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    max. 5% der Versicherungssumme{" "}
                  </td>
                </tr>
              </tbody>
            </table>

            <h6>Hausrat</h6>
            <table className="table table-striped">
              <tbody>
                <tr>
                  <td>
                    <b>Gebäudebeschädigungen</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    max. 15% der Versicherungssumme
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Bekleidung</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    max. 10% der Versicherungssumme
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Lebensmittel</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    max. 1% der Versicherungssumme
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Fernsehgeräte inkl. Zubehör</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    max. 10% der Versicherungssumme
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Radio- und Musikanlagen</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    max. 5% der Versicherungssumme
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>elektrische Werkzeuge insgesamt</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    max. 10% der Versicherungssumme
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>pro Gerät</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    max. 2,5% der Versicherungssumme
                  </td>
                </tr>
              </tbody>
            </table>

            <hr />
          </>
        )}

        <h4 style={{ marginTop: 20, marginBottom: 20 }}>
          {inBBB ? "B:" : "C:"} Gebäudeversicherung zum gleitenden Neuwert
        </h4>
        <div style={{ marginLeft: 25 }}>
          <div
            style={{
              padding: 10,
              backgroundColor: "rgba(200, 200, 200, 0.2)",
              borderTop: "3px solid #de2920",
              marginBottom: 15
            }}
          >
            <ul>
              <li>
                gegen Feuer-, Sturm-, Hagelschäden ggf. Leitungswasserschäden
              </li>
              <li>
                Wir garantieren im Schadensfall den Wiederaufbauwert der Laube /
                des Wochenendhauses sowohl bei Stein- als auch bei Holzgebäuden,
                wenn die bebaute Wohn-/ Nutzfläche richtig angegeben wurde.
              </li>
            </ul>
          </div>
        </div>
      </AccordionContent>
      {!inBBB && (
        <div className="row mb-4">
          <div className="col-12">
            <h5>Unterschiede Komfort/Basis</h5>
          </div>
          <div className="col-12 col-md-6 text-center">
            <a
              className="btn btn-primary"
              target="_blank"
              href="/assets/dokumente/Synopse_abl_Basis_Kombi_Tarif_2000_110419.pdf"
            >
              <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: 15 }} />
              Komfort / Basis Vergleich herunterladen
            </a>
          </div>
          <div className="col-12 col-md-6 text-center">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={() => setShowTable(true)}
            >
              Komfort / Basis Vergleich anzeigen
            </button>
          </div>

          <Modal open={showTable} toggle={() => setShowTable(!showTable)}>
            <div className="table-responsive">
              <table className="table table-striped">
                {defaultThead}
                <tbody>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <b>Überdachungen / Vordächer </b>
                    </td>
                    <td style={{ width: "15%" }}>
                      max. 5% der Versicherungssumme
                    </td>
                    <td style={{ width: "10%" }}>18.000</td>
                    <td style={{ width: "15%" }}>900,00 €</td>
                    <td style={{ width: "10%" }}>100%</td>
                    <td style={{ width: "10%" }}>23.400</td>
                    <td style={{ width: "15%" }}>23.400,00 €</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Sturmschäden</b>
                    </td>
                    <td>max. 15% der Versicherungssumme</td>
                    <td>18.000</td>
                    <td>2.700,00 €</td>
                    <td>100%</td>
                    <td>23.400</td>
                    <td>23.400,00 €</td>
                  </tr>
                  <tr>
                    <td>
                      <b>
                        nur bei Feuerschäden: bei Zäunen/Einfriedungen und
                        Kulturen
                      </b>
                    </td>
                    <td>max. 5% der Versicherungssumme</td>
                    <td>18.000</td>
                    <td>900,00 €</td>
                    <td>10% auch bei Sturmschäden</td>
                    <td>23.400</td>
                    <td>23.400,00 €</td>
                  </tr>
                </tbody>
              </table>
              <h6>Gebäude</h6>
              <table className="table table-striped">
                {defaultThead}
                <tbody>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <b>Gebäudebeschädigungen</b>
                    </td>
                    <td style={{ width: "15%" }}>
                      max. 15% der Versicherungssumme
                    </td>
                    <td style={{ width: "10%" }}>5.100</td>
                    <td style={{ width: "15%" }}>765,00 €</td>
                    <td style={{ width: "10%" }}>100%</td>
                    <td style={{ width: "10%" }}>6.700</td>
                    <td style={{ width: "15%" }}>6.700,00 €</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Bekleidung</b>
                    </td>
                    <td>max. 10% der Versicherungssumme</td>
                    <td>5.100</td>
                    <td>510,00 €</td>
                    <td>100%</td>
                    <td>6.700</td>
                    <td>6.700,00 €</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Lebensmittel</b>
                    </td>
                    <td>max. 1% der Versicherungssumme</td>
                    <td>5.100</td>
                    <td>51,00 €</td>
                    <td>100%</td>
                    <td>6.700</td>
                    <td>6.700,00 €</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Fernsehgeräte inkl. Zubehör</b>
                    </td>
                    <td>max. 10% der Versicherungssumme</td>
                    <td>5.100</td>
                    <td>510,00 €</td>
                    <td>100%</td>
                    <td>6.700</td>
                    <td>6.700,00 €</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Radio- und Musikanlagen</b>
                    </td>
                    <td>max. 5% der Versicherungssumme</td>
                    <td>5.100</td>
                    <td>255,00 €</td>
                    <td>100%</td>
                    <td>6.700</td>
                    <td>6.700,00 €</td>
                  </tr>
                  <tr>
                    <td>
                      <b>elektrische Werkzeuge insgesamt</b>
                    </td>
                    <td>max. 10% der Versicherungssumme</td>
                    <td>5.100</td>
                    <td>510,00 €</td>
                    <td>100%</td>
                    <td>6.700</td>
                    <td>6.700,00 €</td>
                  </tr>
                  <tr>
                    <td>
                      <b>pro Gerät</b>
                    </td>
                    <td>max. 2,5% der Versicherungssumme</td>
                    <td>5.100</td>
                    <td>127,00 €</td>
                    <td>100%</td>
                    <td>6.700</td>
                    <td>6.700,00 €</td>
                  </tr>
                </tbody>
              </table>
              <h6>Glas</h6>
              <table className="table table-striped">
                {defaultThead}
                <tbody>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <b>Glas</b>
                    </td>
                    <td style={{ width: "15%" }}>
                      max. 15% der Hausratversicherung
                    </td>
                    <td style={{ width: "10%" }}>5.100</td>
                    <td style={{ width: "15%" }}>765,00 €</td>
                    <td style={{ width: "10%" }}>100%</td>
                    <td style={{ width: "10%" }}>6.700</td>
                    <td style={{ width: "15%" }}>6.700,00 €</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}
