// @flow
import React from "react";

type Props = {
  onClick: Function,
  label: string,
  iconType: "edit"
};

const icon = {
  edit: (
    <img
      src="/assets/img/edit.png"
      alt="Bearbeiten"
      style={{ opacity: 0.7, width: 20, height: 20, marginRight: 10 }}
    />
  )
};

const buttonStyle = {
  fontSize: 12,
  borderRadius: 50,
  width: 35,
  height: 35,
  backgroundColor: "rgba(150, 150, 150, 0.3)",
  float: "right"
};
export default function EditButton({ onClick, label, iconType }: Props) {
  return (
    <button
      type="button"
      className="btn btn-sm btn-default hover-button"
      onClick={onClick}
      title={label}
      style={buttonStyle}
    >
      {icon[iconType]}
    </button>
  );
}
