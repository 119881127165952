//@flow
import React, { useState, useEffect, useRef } from "react";
import CleaveInput from "cleave.js/react";
import Moment from "moment";

import FormActions from "./../FormActions";

import useFormState from "./../../hooks/useFormState";
// import useTotalCount from "./../../hooks/useTotalCosts";

import ApiService from "./../../services/apiService";

const DATE_FORMAT_DEFAULT = "DD.MM.YYYY";

const VIEW_VALUES = {
  anrede: "Anrede",
  titel: "Titel",
  vorname: "Vorname",
  nachname: "Nachname",
  str: "Straße",
  hsn: "Hausnummer",
  plz: "PLZ",
  ort: "Ort",
  geburtsdatum: "Geburtsdatum",
  telefon: "Telefonnummer",
  beruf: "Tätigkeit",
  beruf_anmerkung: "Beruf",
  // oeffentlicher_dienst: 'Öffentlicher Dienst',
  email: "E-Mail",
  iban: "IBAN",
  bic: "BIC",
  bank: "Name der Bank",

  vorversicherung_name: "Versicherungsunternehmen",
  vorversicherung_versicherungsnummer: "Versicherungsnummer",
  vorversicherung_kuendigung_zum_date: "gekündigt zum",
  vorversicherung_kuendigung_von: "von:",
  vorschaeden_anzahl: "Anzahl",
  vorschaeden_schadenarten: "Schadenart(en)",
  vorschaeden_jahr: "Schadenjahr",
  vorschaeden_summe_schadenhoehe: "Summe Schadenhöhe Euro",
};

const momentToday = Moment(new Date());

const initialState = {
  versicherungsbeginn: momentToday.add(1, "days").format(DATE_FORMAT_DEFAULT),
  personal: {
    anrede: "",
    titel: "",
    vorname: "",
    nachname: "",
    str: "",
    hsn: "",
    plz: "",
    ort: "",
    geburtsdatum: "",
    telefon: "",
    beruf: "",
    beruf_anmerkung: "",
    // oeffentlicher_dienst: false,
    email: "",
    iban: "",
    bic: "",
    bank: "",
    vorversicherung_name: "",
    vorversicherung_versicherungsnummer: "",
    vorversicherung_kuendigung_zum_date: "",
    vorversicherung_kuendigung_von: "",
    vorschaeden_anzahl: "",
    vorschaeden_schadenarten: "",
    vorschaeden_jahr: "",
    vorschaeden_summe_schadenhoehe: "",
  },
  app: {
    sepaMandat: false,
    vorversicherung: null,
    vorschaden: null,
  },
};

const SECTION_VORVERSICHERUNG = [
  "vorversicherung_name",
  "vorversicherung_versicherungsnummer",
  "vorversicherung_kuendigung_zum_date",
  "vorversicherung_kuendigung_von",
];
const SECTION_VORSCHADEN = [
  "vorschaeden_anzahl",
  "vorschaeden_schadenarten",
  "vorschaeden_jahr",
  "vorschaeden_summe_schadenhoehe",
];

function FourthStep({ currentStep, prevStep }) {
  const { form, clear, setForm, savedForm, getByKey } = useFormState(
    initialState
  );
  const [bankInfo, setBankInfo] = useState({
    bank_name: null,
    bic: null,
    error: "",
    iban: form.personal.iban,
  });

  useEffect(() => {
    checkIBAN({ target: { value: form.personal.iban } });
  }, [form.personal.iban]);

  useEffect(() => {
    if (form.personal.vorversicherung_name) {
      if (form.personal.vorversicherung_name.length) {
        setForm({
          ...form,
          app: {
            ...form.app,
            vorversicherung: true,
          },
        });
      }
    }

    if (form.personal.vorschaeden_jahr) {
      if (form.personal.vorschaeden_jahr.length) {
        setForm({
          ...form,
          app: {
            ...form.app,
            vorschaden: true,
          },
        });
      }
    }
  }, []);

  if (getByKey("colonyId") == null || savedForm() == null) {
    window.location = window.location.pathname;
    return null;
  }

  function handleInputChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const oldData = savedForm();
    if (name === "versicherungsbeginn") {
      setForm({
        ...oldData,
        [name]: value,
      });
    } else {
      setForm({
        ...oldData,
        personal: Object.assign({}, oldData.personal, {
          [name]: value,
        }),
        // ...oldData.personal,
        // [name]: value
      });
    }
  }

  function checkIBAN(e) {
    const {
      target: { value },
    } = e;
    ApiService.getBankInfos({ iban: value }).then((resp) => {
      setBankInfo(resp);
      const oldData = savedForm();
      setForm({
        ...oldData,
        ...{
          personal: {
            ...oldData.personal,
            ...(resp.bank_name ? { bank: resp.bank_name } : {}),
            ...(resp.bic ? { bic: resp.bic } : {}),
          },
        },
      });
    });
  }

  function getInput(field) {
    const generalProps = {
      onChange: handleInputChange,
      name: field[0],
      required: field[0] === "telefon" ? false : true,
      className: "form-control",
      value: form.personal[field[0]],
    };
    if (field[0] === "anrede") {
      return (
        <>
          <label htmlFor={field[0]}>{VIEW_VALUES[field[0]]}</label>
          <select {...generalProps}>
            <option value="" disabled defaultValue>
              Bitte wählen...
            </option>
            <option value="Herr">Herr</option>
            <option value="Frau">Frau</option>
            <option value="Diverse">Diverse</option>
            <option value="KGV">Kleingartenverein</option>
            <option value="KGA">Kleingartenanlage</option>
            <option value="BV">Bezirksverband</option>
            <option value="KV">Kreisverband</option>
          </select>
        </>
      );
    }
    if (field[0] === "titel") {
      return (
        <>
          <label htmlFor={field[0]}>{VIEW_VALUES[field[0]]}</label>
          <select {...generalProps} required={false}>
            <option value="" defaultValue>
              kein Titel
            </option>
            <option value="Dr.">Dr.</option>
            <option value="Prof.">Prof.</option>
          </select>
        </>
      );
    }
    if (field[0] === "beruf") {
      return (
        <>
          <label htmlFor={field[0]}>{VIEW_VALUES[field[0]]} *</label>
          <select {...generalProps} required={true}>
            <option value="">Bitte auswählen...</option>
            <option value="Angestellte/r">Angestellte/r</option>
            <option value="Selbstständig">Selbstständig</option>
            <option value="Beamter / ÖD">Beamter / ÖD</option>
            <option value="Künstler">Künstler</option>
            <option value="Rentner / Pensionär">Rentner / Pensionär</option>
            <option value="zur Zeit ohne">zur Zeit ohne</option>
            <option value="Student">Student</option>
            <option value="Sonstiges">Sonstiges</option>
          </select>
        </>
      );
    }

    if (field[0] === "oeffentlicher_dienst") {
      return (
        <>
          <input
            {...generalProps}
            required={false}
            type="checkbox"
            checked={form.personal[field[0]]}
          />
          <label htmlFor={field[0]}>
            {VIEW_VALUES[field[0]]}: {form.personal[field[0]] ? "Ja" : "Nein"}
          </label>
        </>
      );
    }

    if (
      field[0] === "geburtsdatum" ||
      field[0] === "vorversicherung_kuendigung_zum_date"
    ) {
      return (
        <>
          <label htmlFor={field[0]}>
            {VIEW_VALUES[field[0]]} {generalProps.required && "*"}{" "}
          </label>
          <CleaveInput
            {...generalProps}
            placeholder="TT.MM.JJJJ"
            title="Geburtsdatum"
            minLength="10"
            onBlur={(e) => {
              if (e.target.value.length !== 10) {
                e.target.style.borderColor = "red";
              } else {
                e.target.style.borderColor = "#ced4da";
              }
            }}
            options={{
              date: true,
              delimiter: ".",
              datePattern: ["d", "m", "Y"],
            }}
          />
        </>
      );
    }

    if (field[0] === "vorversicherung_kuendigung_von") {
      return (
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <label>von</label>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <input
                required
                type="radio"
                onChange={(e) => {
                  setForm({
                    ...form,
                    personal: {
                      ...form.personal,
                      [field[0]]: "VU",
                    },
                  });
                }}
                name="versicherung"
                id={field[0] + "_vu"}
                checked={form.personal[field[0]] === "VU"}
              />
              <label htmlFor={field[0] + "_vu"}>
                <p className="custom-inputs-label-p">
                  Versicherungsunternehmen
                </p>
              </label>
            </div>
            <div className="col-12">
              <input
                required
                type="radio"
                onChange={(e) => {
                  setForm({
                    ...form,
                    personal: {
                      ...form.personal,
                      [field[0]]: "VN",
                    },
                  });
                }}
                name="versicherung"
                id={field[0] + "_vn"}
                checked={form.personal[field[0]] === "VN"}
              />
              <label htmlFor={field[0] + "_vn"}>
                <p className="custom-inputs-label-p">Versicherungsnehmer</p>
              </label>
            </div>
          </div>
        </div>
      );
    }

    if (field[0] === "plz") {
      return (
        <>
          <label htmlFor={field[0]}>
            {VIEW_VALUES[field[0]]} {generalProps.required && "*"}{" "}
          </label>
          <input {...generalProps} type="text" maxLength="5" />
        </>
      );
    }

    //     "iban": "00000000000000000000",
    // "bic": null,
    // "bank_name": null,
    // "error": "IBAN not valid!"
    if (field[0] === "iban") {
      return (
        <>
          <label htmlFor={field[0]}>
            {VIEW_VALUES[field[0]]} {generalProps.required && "*"}{" "}
          </label>
          <input
            {...generalProps}
            onChange={(e) => {
              // checkIBAN(e);
              handleInputChange(e);
            }}
            type="text"
            maxLength="22"
            minLength="16"
          />
          {generalProps.value.length > 0 && (
            <small className="text-danger">{bankInfo.error}</small>
          )}
        </>
      );
    }

    if (field[0] === "beruf_anmerkung") {
      switch (form.personal.beruf) {
        case "Sonstiges":
          return (
            <>
              <label htmlFor={field[0]}>Anmkerung zur Tätigkeit *</label>
              <input
                {...generalProps}
                type="text"
                aria-describedby={field[0]}
              />
            </>
          );
        case "Student":
          return (
            <>
              <label htmlFor={field[0]}>Studienfach *</label>
              <input
                {...generalProps}
                type="text"
                aria-describedby={field[0]}
              />
            </>
          );
        case "zur Zeit ohne":
          return (
            <>
              <label htmlFor={field[0]}>Anmerkung *</label>
              <input
                {...generalProps}
                type="text"
                aria-describedby={field[0]}
              />
            </>
          );
        case "Beamter / ÖD":
          return (
            <>
              <label htmlFor={field[0]}>Einrichtung *</label>
              <input
                {...generalProps}
                type="text"
                aria-describedby={field[0]}
              />
            </>
          );
        case "Selbstständig":
          return (
            <>
              <label htmlFor={field[0]}>Branche *</label>
              <input
                {...generalProps}
                type="text"
                aria-describedby={field[0]}
              />
            </>
          );

        case "Angestellte/r":
          return (
            <>
              <label htmlFor={field[0]}>Beruf *</label>
              <input
                {...generalProps}
                type="text"
                aria-describedby={field[0]}
              />
            </>
          );
        default:
          return null;
      }
    }

    return (
      <>
        <label htmlFor={field[0]}>
          {VIEW_VALUES[field[0]]} {generalProps.required && "*"}{" "}
        </label>
        <input
          {...generalProps}
          type={field[0] === "email" ? "email" : "text"}
          aria-describedby={field[0]}
        />
      </>
    );
  }

  const formOnlyBasicPersonal = Object.entries(form.personal)
    // filter out bank stuff
    .filter(
      (pi) =>
        [
          "bank",
          "iban",
          "bic",
          ...SECTION_VORVERSICHERUNG,
          ...SECTION_VORSCHADEN,
        ].indexOf(pi[0]) === -1
    );
  const formOnlyBanking = Object.entries(form.personal).filter(
    (pi) => ["bank", "iban", "bic"].indexOf(pi[0]) > -1
  );
  const formOnlyVorversicherung = Object.entries(form.personal).filter(
    (pi) => SECTION_VORVERSICHERUNG.indexOf(pi[0]) > -1
  );
  const formOnlyVorschaden = Object.entries(form.personal).filter(
    (pi) => SECTION_VORSCHADEN.indexOf(pi[0]) > -1
  );

  const startDateInvalid = !Moment(
    form.versicherungsbeginn,
    DATE_FORMAT_DEFAULT
  ).isAfter(Moment(new Date(), DATE_FORMAT_DEFAULT), "day");

  const boxStyles = {
    borderTop: "3px solid #de2920",
    // boxShadow: '0px 0px 10px rgba(100, 100, 100, 0.3)',
  };

  function getClassForField(field) {
    if(["plz", "hsn"].includes(field)) {
      return "col-12 col-md-3";
    } else if(["str", "ort"].includes(field)) {
      return "col-12 col-md-9";
    } else if(["email"].includes(field)) {
      return "col-12 col-md-12";
    } else {
      return "col-12 col-md-6";
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-12 p-4 bg-light" style={boxStyles}>
          <h4 className="mb-4">Persönliche Daten</h4>
          <div className="form-group">
            <div className="row">
              {formOnlyBasicPersonal.map((pi, i) => {
                return (
                  <div key={pi[0]} className={getClassForField(pi[0])}>
                    <div className="form-group">
                      <div
                        className={
                          typeof pi[1] !== "string" ? "custom-inputs" : ""
                        }
                      >
                        {getInput(pi)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-12 bg-light mt-4 p-4" style={boxStyles}>
          <h4 className="mb-4">Bankdaten</h4>
          <div className="form-group">
            <div className="row">
              {formOnlyBanking.map((pi) => {
                return (
                  <div key={pi[0]} className="col-12 col-md-6">
                    <div className="form-group">
                      <div
                        className={
                          typeof pi[1] !== "string" ? "custom-inputs" : ""
                        }
                      >
                        {getInput(pi)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="custom-inputs">
                  <input
                    className="form-control"
                    name="sepa_mandat"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        app: {
                          ...form.app,
                          sepaMandat: !form.app.sepaMandat,
                        },
                      });
                    }}
                    required={true}
                    type="checkbox"
                    checked={form.app.sepaMandat}
                  />
                  <label htmlFor="sepa_mandat">
                    <p className="custom-inputs-label-p">
                      Ich ermächtige die Feuersozietät Berlin Brandenburg
                      (Gläubiger-ID: DE78FS000000155996), die Beiträge von
                      meinem Konto mittels SEPA-Lastschrift einzuziehen.
                      Zugleich weise ich meinen im Antrag genannten
                      Zahlungsdienstleister an, die von dieser Gesellschaft auf
                      mein Konto gezogenen Lastschriften einzulösen.*
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-1">
            <div>
              <small>
                <b>Hinweis: </b> Der Abschluss der Versicherung ist nur mit
                Einzugsermächtigung möglich.
              </small>
            </div>
            <div>
              <small>
                <b>*</b> Pflichtangabe
              </small>
            </div>
          </div>
        </div>

        <div className="col-12 bg-light mt-4 p-4" style={boxStyles}>
          <h4 className="mb-4">Vorversicherung</h4>
          <div className="form-group mb-0">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <div className="custom-inputs" key="vorversicherung">
                      <input
                        className="form-control"
                        name="vorversicherung"
                        id="vorversicherung_ja"
                        onChange={(e) =>
                          setForm({
                            ...form,
                            app: {
                              ...form.app,
                              vorversicherung: true,
                            },
                          })
                        }
                        type="radio"
                        required
                        checked={form.app.vorversicherung === true}
                        style={{ zIndex: 2 }}
                      />
                      <label htmlFor="einverstaendnis_vermittlerinfo_gelesen">
                        <p className="custom-inputs-label-p">Ja</p>
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="custom-inputs" key="vorversicherung">
                      <input
                        className="form-control"
                        name="vorversicherung"
                        id="vorversicherung_nein"
                        onChange={(e) =>
                          setForm({
                            ...form,
                            app: {
                              ...form.app,
                              vorversicherung: false,
                            },
                          })
                        }
                        type="radio"
                        required
                        checked={form.app.vorversicherung === false}
                        style={{ zIndex: 2 }}
                      />
                      <label htmlFor="einverstaendnis_vermittlerinfo_gelesen">
                        <p className="custom-inputs-label-p">Nein</p>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {form.app.vorversicherung &&
                    formOnlyVorversicherung.map((input) => {
                      return (
                        <div key={input[0]} className="col-12 col-md-6">
                          <div className="form-group">
                            <div
                              className={
                                typeof input[1] !== "string"
                                  ? "custom-inputs"
                                  : ""
                              }
                            >
                              {getInput(input)}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 bg-light mt-4 p-4" style={boxStyles}>
          <h4 className="mb-4">Vorschäden</h4>
          <div className="form-group mb-0">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <div className="custom-inputs" key="vorschaden">
                      <input
                        className="form-control"
                        name="vorschaden"
                        id="vorschaden_ja"
                        onChange={(e) =>
                          setForm({
                            ...form,
                            app: {
                              ...form.app,
                              vorschaden: true,
                            },
                          })
                        }
                        type="radio"
                        required
                        checked={form.app.vorschaden === true}
                        style={{ zIndex: 2 }}
                      />
                      <label htmlFor="vorschaden">
                        <p className="custom-inputs-label-p">Ja</p>
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="custom-inputs" key="vorschaden">
                      <input
                        className="form-control"
                        name="vorschaden"
                        id="vorschaden_nein"
                        required
                        onChange={(e) =>
                          setForm({
                            ...form,
                            app: {
                              ...form.app,
                              vorschaden: false,
                            },
                          })
                        }
                        type="radio"
                        checked={form.app.vorschaden === false}
                        style={{ zIndex: 2 }}
                      />
                      <label htmlFor="vorschaden">
                        <p className="custom-inputs-label-p">Nein</p>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {form.app.vorschaden &&
                    formOnlyVorschaden.map((input) => {
                      return (
                        <div key={input[0]} className="col-12 col-md-6">
                          <div className="form-group">
                            <div
                              className={
                                typeof input[1] !== "string"
                                  ? "custom-inputs"
                                  : ""
                              }
                            >
                              {getInput(input)}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12  bg-light mt-4 p-4" style={boxStyles}>
          <h4 className="mb-4">Versicherungsbeginn</h4>
          <div className="form-group">
            <div className="row">
              <div className="col-12 col-md-4">
                <label htmlFor="versicherungsbeginn">
                  Versicherungsbeginn *
                </label>
                <CleaveInput
                  className="form-control"
                  required
                  name="versicherungsbeginn"
                  onChange={handleInputChange}
                  value={form.versicherungsbeginn}
                  placeholder="TT.MM.JJJJ"
                  options={{
                    date: true,
                    delimiter: ".",
                    datePattern: ["d", "m", "Y"],
                  }}
                />
              </div>
              <div className="col-8">
                <br />
                {startDateInvalid && (
                  <div className="alert alert-danger">
                    Der Versicherungsbeginn ist frühestens einen Tag nach
                    Beantragung der Versicherung.
                  </div>
                )}
              </div>
            </div>
            <small>
              <b>Hinweis:</b> Die Hauptfälligkeit ist der 01.01 des Jahres. Bei
              unterjährigem Beginn wird der Beitrag des 1. Jahres nur anteilig
              berechnet.
            </small>
          </div>
        </div>
      </div>
      <div className="col-12">
        <small className="text-muted">Felder mit * sind Pflichtfelder</small>
      </div>
      <div className="col-12">
        <FormActions
          clear={clear}
          currentStep={currentStep}
          prevStep={prevStep}
          enableForwardButton={
            form.personal.geburtsdatum.length !== 10
              ? false
              : bankInfo.error.length || startDateInvalid
              ? false
              : true
          }
        />
      </div>
    </>
  );
}

export default FourthStep;
